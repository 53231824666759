import { Table } from "antd";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getMasterList } from "actions/master";
import CustomPagination from "components/CustomPagination";
import useMasterLogic from "customHooks/useMasterLogic";
import {
	getDyeingItdMasterList,
	getDyeingItdPagination,
	getIsFetchingMasterList,
} from "reducers/master";

import { reset } from "redux-form";
import { filtersFormName } from "../DyeingPlanning/DyeingPlanningFilters";
import { renderActions } from "utils/renderActions";
import ActionModal from "./ActionModal/ActionModal";
import { rejectSampleStockAction } from "actions/dyeing";

const tableName = "dyeing-itd";

const RejectedStock = () => {
	const dispatch = useDispatch();

	const dyeingItdList = useSelector(getDyeingItdMasterList);
	const { pageSize, totalElements, currentPage } = useSelector(
		getDyeingItdPagination
	);

	const isFetchingMasterList = useSelector(getIsFetchingMasterList);

	const getDyeingItdData = useCallback(
		(pageNum = 1, itemsPerPage = 40, query) => {
			dispatch(
				getMasterList(tableName, {
					page: pageNum,
					page_size: 40,
					rejected: 1,
					...query,
				})
			);
		},
		[dispatch]
	);

	const { handlePageChange } = useMasterLogic(
		getDyeingItdData,
		null,
		`dyeing-${tableName}`
	);

	const [show, setShow] = useState(false)
	const [formTitle, setFormTitle] = useState(false)
	const [initialValues, setInitialValues] = useState(null)

	const handleShowForm = (title, value) =>{
		setShow(true)
		setFormTitle(title)
		setInitialValues(value)
	}
    const handleSubmit = async(title, val) =>{
		let issueSampleObj = {
			id: val?.id,
			dyeing_production_id: val?.dyeing_production_id
		}
let query = title === "Issue Sample to Downgrade" ?
"?list_type=sample_downgrade"
: title === "Issue to Redyeing" ?
"?list_type=rejected_redyeing"
:title === "Issue to Downgrade" ?
"?list_type=rejected_downgrade"
: ""
		await dispatch(rejectSampleStockAction(issueSampleObj, query)).then(()=>{
			dispatch(
				getMasterList(tableName, {
					page: 1,
					page_size: 40,
					rejected: 1
				})
			);
		})
    }

	const columns = [
		{
			title: "JC No.",
			dataIndex: "job_card_no",
			key: "job_card_no",
			width: "auto",
		},
		{
			title: "Quality Name",
			dataIndex: ["yarn_quality", "name"],
			key: "yarn_quality_name",
			width: "auto",
		},
		{
			title: "Shade",
			dataIndex: ["shade", "shade_no"],
			key: "shade_no",
			width: "auto",
		},
		{
			title: "Lot Number",
			dataIndex: ["lot", "lot_no"],
			key: "lot_no",
			width: "auto",
		},
		{
			title: "Total Cheese",
			dataIndex: "total_cheese",
			key: "total_cheese",
			width: "auto",
		},
		{
			title: "Total Net Wt.",
			dataIndex: "total_net_weight",
			key: "total_net_weight",
			width: "auto",
		},
		{
			title: "Average Wt.",
			dataIndex: "average_total_weight",
			key: "average_total_weight",
			width: "auto",
		},
		{
			title: "Rejected Cheese",
			dataIndex: "rejected_cheese",
			key: "rejected_cheese",
			width: "auto",
		},
		{
			title: "Rejected Net Wt.",
			dataIndex: "rejected_net_weight",
			key: "rejected_net_weight",
			width: "auto",
		},
		{
			title: "Missing Cheese",
			dataIndex: "missing_cheese",
			key: "missing_cheese",
			width: "auto",
		},
		{
			title: "Missing Net Wt.",
			dataIndex: "missing_net_weight",
			key: "missing_net_weight",
			width: "auto",
		},
		{
			title: "Sample Cheese",
			dataIndex: "sample_cheese",
			key: "sample_cheese",
			width: "auto",
		},
		{
			title: "Sample Net Wt.",
			dataIndex: "sample_net_weight",
			key: "sample_net_weight",
			width: "auto",
		},
		{
			title: "Actions",
			key: "Actions",
			dataIndex: "action",
			width: "10%",
			render: (_, data) => {
				const items = [];

				if (data?.sample_cheese > 0)
					items.push({
						label: "Issue Sample to Downgrade",
						onClick: () => handleShowForm("Issue Sample to Downgrade", data)
					});
				if (data?.rejected_issued === 1 && data?.rejected_cheese > 0)
					items.push({
						label: "Issue to Redyeing",
						onClick: () => handleShowForm("Issue to Redyeing", data)
					});
				if (data?.rejected_issued === 1 && data?.rejected_cheese > 0)
					items.push({
						label: "Issue to Downgrade",
						onClick: () => handleShowForm("Issue to Downgrade", data)
					});

				return renderActions(items);
			},
		},
	];

	const handleFilterSubmit = formData => {
		const formValues = {
			shade_no: formData?.shade_no || null,
			yarn_quality_id: formData?.yarn_quality_id?.value || null,
		};
		if (!formValues?.shade_no) delete formValues?.shade_no;
		if (!formValues?.yarn_quality_id) delete formValues?.yarn_quality_id;

		getDyeingItdData(1, 10, formValues);
	};

	const handleReset = () => {
		dispatch(reset(filtersFormName));
		getDyeingItdData(1, 10);
	};

	return (
		<>
			{/* <LooseStockFilters
				onSubmit={handleFilterSubmit}
				handleReset={handleReset}
			/> */}
				<Table
					dataSource={dyeingItdList}
					columns={columns}
					rowKey={dyeingItdList => dyeingItdList?.id}
					pagination={false}
					loading={isFetchingMasterList}
					bordered
				/>
			{dyeingItdList?.length ? (
				<CustomPagination
					totalPages={Math.ceil(totalElements / pageSize)}
					itemsPerPage={pageSize}
					totalItems={totalElements}
					currentPage={currentPage}
					handlePageChange={handlePageChange}
				/>
			) : null}

	<ActionModal
    open={show}
    setOpen={setShow}
    handleAction={handleSubmit}
    title={formTitle}
    initialValues={initialValues}
    />
		</>
	);
};

export default RejectedStock;
