import React from 'react'
import { Modal } from "react-bootstrap";
import { renderModalBottomButtonsV2 } from 'utils/renderModalBottonButtonsV2';
import { useSelector} from 'react-redux';


const ConfirmationForm = ({handleAction, handleClose, title, initialValues}) => {
    const {isAdding} = useSelector(store => store.dyeing)

        const onCancel = () =>{
            handleClose()
        }
        const handleSubmit = (e)=>{
                e.preventDefault()
                handleAction(title, initialValues)
                handleClose()
        }


  return (
    <form onSubmit={handleSubmit}>
			<Modal.Header closeButton>
				<Modal.Title>
                    <p className='text-secondary fw-light mb-0'>Confirmation For</p>
					<span style={{ textTransform: "capitalize" }}>{title}</span>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
                <p className='fw-bold'>Are you sure you want to continue? This action is irreversible.</p>
			</Modal.Body>
			{renderModalBottomButtonsV2(onCancel, isAdding)}
		</form>
  )
}

export default ConfirmationForm