import axios from 'axios'

import {
    GET_DYEING_DOWNGRADE_LIST,
    ADD_DYEING_DOWNGRADE_JC,
    GET_DYEING_PLANNING_QUERY_DATA,
    CREATE_REDYEING_PLAN,
    CLEAR_ERROR
} from '../constants/dyeing'
import stringifyQueryParams from "utils/stringifyQueryParams";
import apiConfig from "./apiConfig";
import { addNotification } from "./notifications";


export const resetError = () => async(dispatch)=>{
    dispatch({type: CLEAR_ERROR})
}

export const getDyeingDowngradeList = (pageNum=1, itemsPerPage=40, options) => async(dispatch, getState)=>{
        try{
            dispatch({type: GET_DYEING_DOWNGRADE_LIST.REQUEST})
            const { userId } = getState().userDetails;
			const config = { headers: apiConfig?.getHeaders() };

            const { data } = await axios.get(
				`${apiConfig?.baseURL}/v1/user/${userId}/dyeing-downgrade/list?page=${pageNum}&page_size=${itemsPerPage}`,
				config
			);
            if(data?.data) data.data.page = pageNum;
            dispatch({
				type: GET_DYEING_DOWNGRADE_LIST.SUCCESS,
				payload: data?.data
			});

        }catch(error){
            
            dispatch({ type: GET_DYEING_DOWNGRADE_LIST.FAILURE, payload: error?.response?.data?.message });
            dispatch(
                addNotification({ message: error?.response?.data?.message, type: "error" })
            );
        }
}
export const getDyeingPlanningQueryData = (query, singleCall) => async(dispatch, getState)=>{
        try{
            dispatch({type: GET_DYEING_PLANNING_QUERY_DATA.REQUEST})
            const { userId } = getState().userDetails;
			const config = { headers: apiConfig?.getHeaders() };
            const q = stringifyQueryParams(query)

            const { data } = await axios.get(
				`${apiConfig?.baseURL}/v1/user/${userId}/dyeing-planning/list?${q}&dropdown=1&status=2&sort=id`,
				config
			);
           
            dispatch({
				type: GET_DYEING_PLANNING_QUERY_DATA.SUCCESS,
				payload: data?.data
			});
            if(singleCall){
                return data?.data?.results;
            }

        }catch(error){
            dispatch({ type: GET_DYEING_PLANNING_QUERY_DATA.FAILURE, payload: error?.response?.data?.message });
            dispatch(
                addNotification({ message: error?.response?.data?.message, type: "error" })
            );
        }
}

export const IdValueGenerator = (query, singleCall) => async(dispatch, getState)=>{
    try{
        dispatch({type: GET_DYEING_PLANNING_QUERY_DATA.REQUEST})
        const { userId } = getState().userDetails;
        const config = { headers: apiConfig?.getHeaders() };
        const q = stringifyQueryParams(query)
        const { data } = await axios.get(
            `${apiConfig?.baseURL}/v1/user/${userId}/dyeing-planning/list?${q}`,
            config
        );
        //was called in prev downgrade comp after selecting planning id
        // ${apiConfig?.baseURL}/v1/user/${userId}/dyeing-planning/list?id=${dyeingPlanningId?.value}
        dispatch({ type: CLEAR_ERROR});
            return data?.data;
        }catch(error){
            dispatch({ type: GET_DYEING_PLANNING_QUERY_DATA.FAILURE, payload: null });
            dispatch(
                addNotification({ message: "Unable to fetch addition information.", type: "error" })
            );
        }
}

export const createDyeingDowngradeJC = (formData) => async(dispatch, getState)=>{

    try{
        dispatch({type: ADD_DYEING_DOWNGRADE_JC.REQUEST})
        const { userId } = getState().userDetails;
        const config = { headers: apiConfig?.getHeaders() };

        const { data } = await axios.post(
            `${apiConfig?.baseURL}/v1/user/${userId}/dyeing-downgrade/create`,
            formData,
            config
        );
        
        dispatch({
            type: ADD_DYEING_DOWNGRADE_JC.SUCCESS,
            payload: data?.data?.message
        });
        dispatch(
            addNotification({ message: data?.data?.message, type: "success" })
        );


    }catch(error){
        dispatch({ type: ADD_DYEING_DOWNGRADE_JC.FAILURE, payload: error?.response?.data?.message });
        
    }
}

export const createDyeingRedyeingPlan = (formData, type='Redyeing') => async(dispatch, getState)=>{

    try{
        dispatch({type: CREATE_REDYEING_PLAN.REQUEST})
        const { userId } = getState().userDetails;
        const config = { headers: apiConfig?.getHeaders() };

        const { data } = await axios.post(
            `${apiConfig?.baseURL}/v1/user/${userId}/dyeing-planning/redyeing/create?planning_type=${type}`,
            formData,
            config
        );
        
        dispatch({
            type: CREATE_REDYEING_PLAN.SUCCESS,
            payload: data?.data?.message
        });
        dispatch(
            addNotification({ message: data?.data?.message, type: "success" })
        );


    }catch(error){
        dispatch({ type: CREATE_REDYEING_PLAN.FAILURE, payload: error?.response?.data?.message });
        
    }
}


export const rejectSampleStockAction = (formData, query="") => async(dispatch, getState)=>{

    try{
        dispatch({type: CREATE_REDYEING_PLAN.REQUEST})
        const { userId } = getState().userDetails;
        const config = { headers: apiConfig?.getHeaders() };

        const { data } = await axios.post(
            `${apiConfig?.baseURL}/v1/user/${userId}/dyeing-itd/sample-rejected/create${query}`,
            formData,
            config
        );
        
        dispatch({
            type: CREATE_REDYEING_PLAN.SUCCESS,
            payload: data?.data?.message
        });
        dispatch(
            addNotification({ message: data?.data?.message, type: "success" })
        );


    }catch(error){
        dispatch({ type: CREATE_REDYEING_PLAN.FAILURE, payload: error?.response?.data?.message });
        
    }
}


export const EditBatchNo = (formData, type) => async(dispatch, getState)=>{

    try{
        dispatch({type: CREATE_REDYEING_PLAN.REQUEST})
        const { userId } = getState().userDetails;
        const config = { headers: apiConfig?.getHeaders() };

        const { data } = await axios.post(
            `${apiConfig?.baseURL}/v1/user/${userId}/batch-no/edit?list_type=${type}`,
            formData,
            config
        );
        
        dispatch({
            type: CREATE_REDYEING_PLAN.SUCCESS,
            payload: data?.data?.message
        });
        dispatch(
            addNotification({ message: data?.data?.message, type: "success" })
        );
        return data;


    }catch(error){
        dispatch({ type: CREATE_REDYEING_PLAN.FAILURE, payload: error?.response?.data?.message });
        
    }
}