import { notification } from "antd";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { persistor, store } from "./store";

notification.config({
	placement: "topRight",
	top: 165,
});

const root = ReactDOM.createRoot(document.getElementById("root"));

document.addEventListener("wheel", function(event){
if(document.activeElement.type === "number"){
	document.activeElement.blur();
}
});

root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<App />
		</PersistGate>
	</Provider>
);
