import { createRequestTypes } from "utils/createRequestTypes";

export const GET_PACKING_LIST = createRequestTypes("GET_PACKING_LIST");
export const CREATE_PACKING = createRequestTypes("CREATE_PACKING");
export const GET_CARTON_LIST = createRequestTypes("GET_CARTON_LIST");
export const GET_CARTON_ITEM_LIST = createRequestTypes("GET_CARTON_ITEM_LIST");
// for add challan form.
export const GET_SALE_ORDER_NUMBER = createRequestTypes("GET_SALE_ORDER_NUMBER");
export const GET_SALE_ORDER_LIST = createRequestTypes("GET_SALE_ORDER_LIST");
export const GET_SELECT_CARTON_LIST = createRequestTypes("GET_SELECT_CARTON_LIST");
export const GET_TRANSPORT_LIST = createRequestTypes("GET_TRANSPORT_LIST");
//-------------------------
//four counter and it's related actions.
export const GET_COUNTER_LIST = createRequestTypes("GET_COUNTER_LIST");
export const CREATE_COUNTER_CARTON = createRequestTypes("CREATE_COUNTER_CARTON");
export const GET_COUNTER_CARTON = createRequestTypes("GET_COUNTER_CARTON");
//-----------------------------


