import { Table } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getMasterList } from "actions/master";
import CustomPagination from "components/CustomPagination";
import StatusSegments from "components/StatusSegments";
import useMasterLogic from "customHooks/useMasterLogic";
import {
	getDyeingItdMasterList,
	getDyeingItdPagination,
	getIsFetchingMasterList,
} from "reducers/master";
import Filter from "utils/V2Comp/Filter";


const tableName = "dyeing-itd";

const ListItd = () => {
	const { status } = useParams();
	const dispatch = useDispatch();

	const dyeingItdList = useSelector(getDyeingItdMasterList);

	const { pageSize, totalElements, currentPage } = useSelector(
		getDyeingItdPagination
	);
	const isFetchingMasterList = useSelector(getIsFetchingMasterList);
	const [queryData, setQueryData] = useState({})

	useEffect(()=>{
		setQueryData({})
	},[status])

	const getShadeData = useCallback(
		(pageNum = 1, itemsPerPage = 20) => {
			dispatch(
				getMasterList(tableName, {
					page: pageNum,
					page_size: 40,
					status,
					...queryData,
				})
			);
		},
		[status, dispatch, queryData]
	);

	const { handlePageChange } = useMasterLogic(getShadeData);

	const columns = [
		{
			title: "Job Card No",
			dataIndex: "job_card_no",
			key: "job_card_no",
			width: "auto",
		},
		{
			title: "Job Card Date",
			dataIndex: "job_card_date",
			key: "job_card_date",
			width: "auto",
		},
		{
			title: "Machine No",
			dataIndex: "machine_no",
			key: "machine_no",
			width: "auto",
		},
		{
			title: "Party Name",
			dataIndex: "party_name",
			key: "party_name",
			width: "auto",
		},
		{
			title: "Quality Name",
			dataIndex: ["yarn_quality", "name"],
			key: "yarn_quality_name",
			width: "auto",
		},
		{
			title: "Lot No",
			dataIndex: ["lot", "lot_no"],
			key: "lot_no",
			width: "auto",
		},
		{
			title: "Process",
			dataIndex: "process_name",
			key: "process_name",
			width: "auto",
		},
		{
			title: "No. Of Cheese",
			dataIndex: "total_cheese",
			key: "total_cheese",
			width: "auto",
		},
		{
			title: "Quantity (Nt Wt)",
			dataIndex: "total_net_weight",
			key: "total_net_weight",
			width: "auto",
		},
		{
			title: "Issued Department",
			dataIndex: "issue_to_department",
			key: "issue_to_department",
			width: "auto",
		},
	];

	return (
		<>
			<StatusSegments
				masterName={"dyeing-dyeing-itd/list_itd/list"}
				options={[
					{ label: "Unused", value: 1 },
					{ label: "Used", value: 2 },
				]}
			/>
			<Filter queryData={queryData} setQueryData={setQueryData} toShow={['yarn_quality_id', 'shade_id', 'lot_id', 'job_card_no']}/>
			<Table
				dataSource={dyeingItdList}
				columns={columns}
				rowKey={dyeingItdList => dyeingItdList?.id}
				pagination={false}
				loading={isFetchingMasterList}
				bordered
			/>
			{dyeingItdList?.length ? (
				<CustomPagination
					totalPages={Math.ceil(totalElements / pageSize)}
					itemsPerPage={pageSize}
					totalItems={totalElements}
					currentPage={currentPage}
					handlePageChange={handlePageChange}
				/>
			) : null}
		</>
	);
};

export default ListItd;
