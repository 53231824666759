import {
    GET_PACKING_LIST,
    CREATE_PACKING,
    GET_CARTON_LIST,
    GET_CARTON_ITEM_LIST,
    GET_SALE_ORDER_NUMBER,
    GET_SALE_ORDER_LIST,
    GET_SELECT_CARTON_LIST,
    GET_COUNTER_LIST,
    CREATE_COUNTER_CARTON,
    GET_COUNTER_CARTON,
    GET_TRANSPORT_LIST
} from '../constants/packing'
//
const initialState = {
    isFetching:false,
    isUpdating:false,
    packingData:[],
    packingPagination:{
        pageSize: 40,
		totalElements: 40,
		currentPage: 1,
    },
    cartonData:[],
    cartonPagination:{
        pageSize: 40,
		totalElements: 40,
		currentPage: 1,
    },
    saleOrderNo:[],
    saleOrderList:[],
    transportList: [],
    selectCartonList:[],
    counter_list: null,
    counter_carton_list: null,
    carton_item_list: null,

}

export const packingReducer = (state = initialState, { type, payload }) => {
    switch(type){
        case GET_PACKING_LIST.REQUEST:
            return {...state, isFetching:true};
        case GET_PACKING_LIST.SUCCESS:
            return {...state,isFetching:false, packingData:payload?.results, packingPagination: {
                pageSize: 40,
                currentPage:payload?.page || 1,
		        totalElements: payload?.count,
            }};
        case GET_PACKING_LIST.FAILURE:
            return {...state, isFetching:false, error:payload};
        case CREATE_PACKING.REQUEST:
            return {...state, isUpdating:true};
        case CREATE_PACKING.SUCCESS:
            return {...state, isUpdating: false};
        case CREATE_PACKING.FAILURE:
            return {...state, isUpdating: false};
        case GET_CARTON_LIST.REQUEST:
            return {...state, isFetching:true};
        case GET_CARTON_LIST.SUCCESS:
            return {...state,isFetching:false, cartonData:payload?.results, cartonPagination: {
                pageSize: 40,
                currentPage:payload?.page || 1,
                totalElements: payload?.count,
            }};
        case GET_CARTON_LIST.FAILURE:
            return {...state, isFetching:false, error:payload};
        case GET_CARTON_ITEM_LIST.REQUEST:
            return {...state, isFetching:true};
        case GET_CARTON_ITEM_LIST.SUCCESS:
            return {...state,isFetching:false, carton_item_list:payload};
        case GET_CARTON_ITEM_LIST.FAILURE:
            return {...state, isFetching:false, error:payload};
        case GET_SALE_ORDER_NUMBER.REQUEST:
            return {...state, isFetching:true};
        case GET_SALE_ORDER_NUMBER.SUCCESS:
            return {...state,isFetching:false, saleOrderNo:payload?.results};
        case GET_SALE_ORDER_NUMBER.FAILURE:
            return {...state, isFetching:false, error:payload};
        case GET_SALE_ORDER_LIST.REQUEST:
            return {...state, isFetching:true};
        case GET_SALE_ORDER_LIST.SUCCESS:
            return {...state,isFetching:false, saleOrderList:payload};
        case GET_SALE_ORDER_LIST.FAILURE:
            return {...state, isFetching:false, error:payload};
        case GET_TRANSPORT_LIST.REQUEST:
            return {...state, isFetching:true};
        case GET_TRANSPORT_LIST.SUCCESS:
            return {...state,isFetching:false, transportList:payload};
        case GET_TRANSPORT_LIST.FAILURE:
            return {...state, isFetching:false, error:payload};
        case GET_SELECT_CARTON_LIST.REQUEST:
            return {...state, isFetching:true};
        case GET_SELECT_CARTON_LIST.SUCCESS:
            return {...state,isFetching:false, selectCartonList:payload?.results};
        case GET_SELECT_CARTON_LIST.FAILURE:
            return {...state, isFetching:false, error:payload};
        case GET_COUNTER_LIST.REQUEST:
            return {...state, isFetching:true};
        case GET_COUNTER_LIST.SUCCESS:
            return {...state,isFetching:false, counter_list:payload?.results};
        case GET_COUNTER_LIST.FAILURE:
            return {...state, isFetching:false, error:payload};
        case CREATE_COUNTER_CARTON.REQUEST:
            return {...state, isUpdating:true};
        case CREATE_COUNTER_CARTON.SUCCESS:
            return {...state,isUpdating:false};
        case CREATE_COUNTER_CARTON.FAILURE:
            return {...state, isUpdating:false, error:payload};
        case GET_COUNTER_CARTON.REQUEST:
            return {...state, isFetching:true};
        case GET_COUNTER_CARTON.SUCCESS:
            return {...state,isFetching:false, counter_carton_list:payload?.results};
        case GET_COUNTER_CARTON.FAILURE:
            return {...state, isFetching:false, error:payload};
        default:
            return state;
    }
}