import { Suspense, lazy } from "react";
import { CgOrganisation } from "react-icons/cg";
import { MdFormatPaint, MdRawOn, MdSpaceDashboard } from "react-icons/md";
import { GiHeatHaze } from "react-icons/gi";
import { TbWashMachine, TbSpiral } from "react-icons/tb";
import { Outlet, Route, Routes } from "react-router-dom";
import { FaBoxArchive } from "react-icons/fa6";

import { Spin } from "antd";
import Dashboard from "components/dashboard/Dashboard";
import DyeingItd from "components/dyeing/DyeingItd/DyeingItd";

//master imports
const FinancialYear = lazy(() =>
	import("components/masters/financialYear/FinancialYear")
);
const Party = lazy(() => import("components/masters/party/Party"));
const Transport = lazy(() => import("components/masters/transport/Transport"));
const Department = lazy(() =>
	import("components/masters/department/Department")
);
const Location = lazy(() => import("components/masters/location/Location"));
const Process = lazy(() => import("components/masters/process/Process"));
const Denier = lazy(() => import("components/masters/denier/Denier"));
const Filament = lazy(() => import("components/masters/filament/Filament"));
const Shade = lazy(() => import("components/masters/shade/Shade"));
const ShadeFamily = lazy(() => import("components/masters/shadeFamily/ShadeFamilyMain"));
const DyesNchemicals = lazy(() => import("components/masters/dyesNchemicals/DyesNchemicalsMain"));
const Recipe = lazy(() => import("components/masters/recipe/RecipeMain"));
const Program = lazy(() => import("components/masters/program/ProgramMain"));
const Count = lazy(() => import("components/masters/count/Count"));
const Lot = lazy(() => import("components/masters/lot/Lot"));
const YarnQuality = lazy(() =>
	import("components/masters/yarnQuality/YarnQuality")
);

// rm imports
const Grn = lazy(() => import("components/rawMaterial/Grn/Grn"));
const GrnWrapper = lazy(() => import("components/rawMaterial/Grn/GrnWrapper"));
const IssueToDepartment = lazy(() =>
	import("components/rawMaterial/IssueToDepartment/IssueToDepartment")
);
const IssueToDepartmentWrapper = lazy(() =>
	import("components/rawMaterial/IssueToDepartment/IssueToDepartmentWrapper")
);
const Outward = lazy(() => import("components/rawMaterial/Outward/Outward"));
const OutwardWrapper = lazy(() =>
	import("components/rawMaterial/Outward/OutwardWrapper")
);
const QualityCheck = lazy(() =>
	import("components/rawMaterial/QualityCheck/QualityCheck")
);
const QualityCheckWrapper = lazy(() =>
	import("components/rawMaterial/QualityCheck/QualityCheckWrapper")
);
const Repacking = lazy(() =>
	import("components/rawMaterial/Repacking/Repacking")
);
const RepackingWrapper = lazy(() =>
	import("components/rawMaterial/Repacking/RepackingWrapper")
);
const RmStock = lazy(() => import("components/rawMaterial/Stock/RmStock"));
const Wip = lazy(() => import("components/rawMaterial/Wip/Wip"));
const WipWrapper = lazy(() => import("components/rawMaterial/Wip/WipWrapper"));

// dyeing imports
const DyeingPlanningWrapper = lazy(() =>
	import("components/dyeing/DyeingPlanning/DyeingPlanningWrapper")
);
const JobCardFormWrapper = lazy(() =>
	import("components/dyeing/DyeingPlanning/JobCardFormWrapper")
);
const DyeingProduction = lazy(() =>
	import("components/dyeing/DyeingProduction/DyeingProduction")
);
const DyeingQc = lazy(() => import("components/dyeing/DyeingQc/DyeingQc"));
const SaleOrder = lazy(() => import("components/dyeing/SaleOrder/SaleOrder"));
const SaleOrderFormWrapper = lazy(() =>
	import("components/dyeing/SaleOrder/SaleOrderWrapper")
);
const DyeingStock = lazy(() => import("components/dyeing/Stock/DyeingStock"));
const DyeingDowngrade = lazy(()=>import ("components/dyeing/DyeingDowngrade/DyeingDowngrade"))

// //not found page
// const NotFound = lazy(()=> import('./NotFound'))
//heat setting import
const HeatSetting = lazy(()=> import("components/HeatSetting/HeatSetting"));
//soft department
const SoftStock = lazy(()=>import("components/SoftDepartment/SoftStock"))
const SoftProduction = lazy(()=>import("components/SoftDepartment/SoftProduction"))
const SoftItd = lazy(()=>import("components/SoftDepartment/SoftItd"))
//winding
const WindingStock = lazy(()=>import("components/winding/WindingStock"))
const WindingOperation = lazy(()=>import("components/winding/WindingOperation"))
const WindingQC = lazy(()=>import("components/winding/WindingQC"))
const WindingPQ = lazy(()=>import("components/winding/WindingPQ"))
// packing
const PackingList = lazy(()=>import("components/packing/PackingList"))
const CartonList = lazy(()=>import("components/packing/CartonList"))
const AddChallan = lazy(()=>import("components/packing/AddChallan"))
const ChallanList = lazy(()=>import("components/packing/ChalanList"))
const GenerateInvoice = lazy(()=>import("components/packing/GenerateInvoice"))
const InvoiceList = lazy(()=>import("components/packing/InvoiceList"))
const RawStockAddChallan = lazy(()=> import("components/rawMaterial/Stock/RawStockAddChallan")) ;

const getFallbackUI = () => (
	<div className="d-flex h-100">
		<Spin
			className="d-flex justify-content-center align-items-center flex-grow-1"
			size="large"
		/>
	</div>
);

const getItem = (label, key, icon, children, onClick) => {
	return {
		key,
		icon,
		children,
		label,
		onClick,
	};
};

export const sidebarItemsList = [
	getItem("Dashboard", "dashboard", <MdSpaceDashboard className="fs-4" />),
	getItem("Masters", "masters", <CgOrganisation className="fs-4" />, [
		getItem("Party", "party"),
		getItem("Financial Year", "financial-year"),
		getItem("Transport", "transport"),
		getItem("Department", "department"),
		getItem("Location", "location"),
		getItem("Process", "process"),
		getItem("Denier", "denier"),
		getItem("Filament", "filament"),
		getItem("Count", "count"),
		getItem("Shade", "shade"),
		getItem("Shade Family", "shade-family"),
		getItem("Dyes & Chemicals", "dyes-and-chemicals"),
		getItem("Recipe", "recipe"),
		getItem("Program", "program"),
		getItem("Lot", "lot"),
		getItem("Yarn Quality", "yarn-quality"),
	]),
	getItem("Raw Material", "raw-material", <MdRawOn className="fs-4" />, [
		getItem("GRN", "raw-material-grn"),
		getItem("Repacking", "raw-material-repacking"),
		getItem("WIP", "raw-material-wip"),
		getItem("Quality Check", "raw-material-quality-check"),
		getItem("Issue To Department", "raw-material-issue-to-department"),
		getItem("Outward", "raw-material-outward"),
		getItem("Stock", "raw-material-stock"),
	]),
	getItem("Dyeing", "dyeing", <MdFormatPaint className="fs-4" />, [
		getItem("Sale Order", "dyeing-sale-order"),
		getItem("Dyeing Planning", "dyeing-dyeing-planning"),
		getItem("Dyeing Production", "dyeing-dyeing-production"),
		getItem("Dyeing QC", "dyeing-dyeing-qc"),
		getItem("Dyeing Downgrade", "dyeing-downgrade"),
		getItem("Dyeing ITD", "dyeing-dyeing-itd"),
		getItem("Stock", "dyeing-stock"),
	]),
	getItem("Heat Setting", "heat-setting", <GiHeatHaze className="fs-4" />),
	getItem("Soft Department", "soft-department", <TbWashMachine className="fs-4" />, [
		getItem("Soft Stock", "soft-stock"),
		getItem("Soft Production", "soft-production"),
		getItem("Soft ITD", "soft-itd")
	]),
	getItem("Winding", "winding", <TbSpiral className="fs-4" />, [
		getItem("Winding Stock", "winding-stock"),
		getItem("Winding Production", "winding-production"),
		getItem("Winding QC", "winding-qc"),
		getItem("Winding PQ", "winding-pq")
	]),
	getItem("Packing", "packing", <FaBoxArchive className="fs-4"/>,[
		getItem("Packing", "packing"),
		getItem("Cartons", "cartons"),
		getItem("Challan", "challan-list"),
		getItem("Invoice", "invoice-list"),
	]),
];

const AuthorizedRoutes = () => {
	return (
		<>
			{/* masters routes */}
			<Suspense fallback={getFallbackUI()}>
				<Routes>
					<Route
						path="/financial-year/:status"
						element={<FinancialYear />}
						exact
					/>
					<Route path="/party/:status" element={<Party />} exact />
					<Route path="/transport/:status" element={<Transport />} exact />
					<Route path="/department/:status" element={<Department />} exact />
					<Route path="/location/:status" element={<Location />} exact />
					<Route path="/process/:status" element={<Process />} exact />
					<Route path="/denier/:status" element={<Denier />} exact />
					<Route path="/filament/:status" element={<Filament />} exact />
					<Route path="/shade/:status" element={<Shade />} exact />
					<Route path="/shade-family/:status" element={<ShadeFamily />} exact />
					<Route path="/dyes-and-chemicals/:status" element={<DyesNchemicals />} exact />
					<Route path="/recipe/:status" element={<Recipe />} exact />
					<Route path="/program/:status" element={<Program />} exact />
					<Route path="/count/:status" element={<Count />} exact />
					<Route path="/lot/:status" element={<Lot />} exact />
					<Route path="/yarn-quality/:status" element={<YarnQuality />} exact />

			{/* raw material routes */}
					<Route path="/raw-material-grn/" element={<Outlet />}>
						<Route index element={<Grn />} />
						<Route path="add" element={<GrnWrapper />} />
						<Route path=":mode/:id" element={<GrnWrapper />} />
						<Route path=":status" element={<Grn />} />
					</Route>

					<Route path="/raw-material-repacking/" element={<Outlet />}>
						<Route index element={<Repacking />} />
						<Route path="add" element={<RepackingWrapper />} />
						<Route path=":mode/:id" element={<RepackingWrapper />} />
						<Route path=":status" element={<Repacking />} />
					</Route>

					<Route path="/raw-material-wip/" element={<Outlet />}>
						<Route index element={<Wip />} />
						<Route path="add" element={<WipWrapper />} />
						<Route path=":mode/:id" element={<WipWrapper />} />
						<Route path=":status" element={<Wip />} />
					</Route>

					<Route path="/raw-material-quality-check/" element={<Outlet />}>
						<Route index element={<QualityCheck />} />
						<Route path="add" element={<QualityCheckWrapper />} />
						<Route path=":mode/:id" element={<QualityCheckWrapper />} />
						<Route path=":status" element={<QualityCheck />} />
					</Route>

					<Route path="/raw-material-issue-to-department/" element={<Outlet />}>
						<Route index element={<IssueToDepartment />} />
						<Route path="add" element={<IssueToDepartmentWrapper />} />
						<Route path=":mode/:id" element={<IssueToDepartmentWrapper />} />
						<Route path=":status" element={<IssueToDepartment />} />
					</Route>

					<Route path="/raw-material-outward/" element={<Outlet />}>
						<Route index element={<Outward />} />
						<Route path="add" element={<OutwardWrapper />} />
						<Route path=":mode/:id" element={<OutwardWrapper />} />
						<Route path=":status" element={<Outward />} />
					</Route>
					<Route
						path="/raw-material-stock/:module/:type"
						element={<RmStock />}
						exact
					/>
					<Route
						path="/raw-material-stock/add-challan"
						element={<RawStockAddChallan />}
						exact
					/>

			{/* Dyeing department routes */}
					<Route path="/dyeing-sale-order/" element={<Outlet />}>
						<Route index element={<SaleOrder />} />
						<Route path="add" element={<SaleOrderFormWrapper />} />
						<Route path=":mode/:id" element={<SaleOrderFormWrapper />} />
						<Route path=":status" element={<SaleOrder />} />
					</Route>

					<Route
						path="/dyeing-dyeing-planning/create-jc"
						element={<JobCardFormWrapper />}
						exact
					/>
					<Route
						path="/dyeing-dyeing-planning/:status"
						element={<DyeingPlanningWrapper />}
						exact
					/>
					<Route
						path="/dyeing-dyeing-production/:status"
						element={<DyeingProduction />}
						exact
					/>
					<Route
						path="/dyeing-dyeing-qc/:status"
						element={<DyeingQc />}
						exact
					/>
					<Route
						path="/dyeing-downgrade/:status"
						element={<DyeingDowngrade />}
						exact
					/>
					<Route
						path="/dyeing-dyeing-itd/:type/:list_type/:status"
						element={<DyeingItd />}
						exact
					/>

					<Route
						path="/dyeing-stock/:module/:type"
						element={<DyeingStock />}
						exact
					/>
					{/* heat setting routes */}
					<Route path="/heat-setting" element={<HeatSetting />}/>

					{/* Soft routes */}
					<Route path='/soft-stock' element={<SoftStock/>}/>
					<Route path='/soft-production' element={<SoftProduction/>}/>
					<Route path='/soft-itd' element={<SoftItd/>}/>

					{/* winding */}
					<Route path="/winding-stock" element={<WindingStock/>}/>
					<Route path="/winding-production/:type" element={<WindingOperation/>}/>
					<Route path="/winding-qc" element={<WindingQC/>}/>
					<Route path="/winding-pq" element={<WindingPQ/>}/>

					{/* {packing} */}
					<Route path="/packing" element={<PackingList/>}/>
					<Route path="/cartons" element={<CartonList/>}/>
					<Route path="/add-challan" element={<AddChallan/>}/>
					<Route path="/challan-list" element={<ChallanList/>}/>
					<Route path="/generate-invoice" element={<GenerateInvoice/>}/>
					<Route path="/invoice-list" element={<InvoiceList/>}/>

					{/* general routes */}
					<Route path="/dashboard" element={<Dashboard />} exact />
					{/* Not found route */}
					{/* <Route path="*" element={<NotFound />} /> */}
				</Routes>
			</Suspense>
		</>
	);
};

export default AuthorizedRoutes;
