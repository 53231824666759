import {
    GET_SOFT_LIST,
    GET_SOFT_PROD_LIST,
    GET_SOFT_ITD_LIST,
    UPDATE_SOFT_PROD_LIST,
    CLEAR_ERROR
} from '../constants/softDept'


const initialState = {
    isFetching:false,
    isUpdating:false,
    isAdding:false,
    softData:[],
    softPagination:{
        pageSize: 40,
		totalElements: 40,
		currentPage: 1,
    },
    softProdData:[],
    softProdPagination:{
        pageSize: 40,
		totalElements: 40,
		currentPage: 1,
    },
    softItdData:[],
    softItdPagination:{
        pageSize: 40,
		totalElements: 40,
		currentPage: 1,
    },
    message:null,
    error:null,
}

export const softDeptReducer = (state = initialState, { type, payload }) => {
    switch(type){
        case CLEAR_ERROR:
            return {...state, error:null, message:null};
        case GET_SOFT_LIST.REQUEST:
            return {...state, isFetching:true};
        case GET_SOFT_LIST.SUCCESS:
            return {...state,isFetching:false, softData:payload?.results, softPagination: {
                pageSize: 40,
                currentPage:payload?.page || 1,
		        totalElements: payload?.count,
            }};
        case GET_SOFT_LIST.FAILURE:
            return {...state, isFetching:false, error:payload};
        case GET_SOFT_PROD_LIST.REQUEST:
            return {...state, isFetching:true};
        case GET_SOFT_PROD_LIST.SUCCESS:
            return {...state,isFetching:false, softProdData:payload?.results, softProdPagination: {
                pageSize: 40,
                currentPage:payload?.page || 1,
		        totalElements: payload?.count,
            }};
        case GET_SOFT_PROD_LIST.FAILURE:
            return {...state, isFetching:false, error:payload};
        case UPDATE_SOFT_PROD_LIST.REQUEST:
            return {...state, isUpdating:true};
        case UPDATE_SOFT_PROD_LIST.SUCCESS:
            return {...state,isUpdating:false};
        case UPDATE_SOFT_PROD_LIST.FAILURE:
            return {...state, isUpdating:false};
        case GET_SOFT_ITD_LIST.REQUEST:
            return {...state, isFetching:true};
        case GET_SOFT_ITD_LIST.SUCCESS:
            return {...state,isFetching:false, softItdData:payload?.results, softItdPagination: {
                pageSize: 40,
                currentPage:payload?.page || 1,
                totalElements: payload?.count,
            }};
        case GET_SOFT_ITD_LIST.FAILURE:
            return {...state, isFetching:false, error:payload};
        default:
            return state;
    }
}