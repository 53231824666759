import React from 'react'
import { useState } from 'react';
import { FaFilter, FaEraser } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { Input, Select } from 'antd';
import { gradeOptions } from 'components/rawMaterial/Grn/grnConstants';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getLotList, getQualityList, getShadeList, getJobCardList, getPartyNameList, getSlipNoList } from 'actions/filter';
import { shadeNatureOptions } from 'components/masters/shade/shadeConstants';
import { getDyesNChemicalsList, getShadeFamilyList } from 'actions/master';
import { useDebouncedCallback } from 'use-debounce';

const Filter = ({queryData, setQueryData, toShow=[]}) => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
   
    const processTypeOptions = [
        {value:'PCW', label:'PCW'},
        {value:'Y-Cone', label:'Y-Cone'},
        {value:'A-Cone', label:'A-Cone'},
        {value:'Pineapple', label:'Pineapple'},
    ]

    const checkingTypeOptions = [
        {value:'ReCheck', label:'ReCheck'},
        {value:'Fresh', label:'Fresh'}
    ]
    const {shadeListData, lotListData, qualityListData, jobCardListData,partyNameListData,slipNoListData,
         isShadeFetching, isLotFetching, isQualityFetching, isJobCardFetching, isPartyNameFetching, isSlipFetching} = useSelector(state=> state.filter)
    
    //for shade family:
    const {isFetchingMasterList,shadeFamilyList, dyesNChemicalsList } = useSelector(store => store.masterDetails)
	const [shadeFamilyDropdown, setShadeFamilyDropdown] = useState([])
    ////
    //for dyes and chemical:
    const [dyesChemicalDropdown, setDyesChemicalDropdown] = useState([])

    useEffect(()=>{

       toShow?.includes('party_id') && dispatch(getPartyNameList())
       toShow?.includes('yarn_quality_id') && dispatch(getQualityList())
       toShow?.includes('lot_id') && dispatch(getLotList())
       toShow?.includes('shade_id') && dispatch(getShadeList())
       toShow?.includes('job_card_no') && dispatch(getJobCardList())
       toShow?.includes('shade_family') && dispatch(getShadeFamilyList(1, 40, {status:'2'}))
       toShow?.includes('dyes_chemical_id') && dispatch(getDyesNChemicalsList(1, 40, {status:'2'}))
       toShow?.includes('slip_id') && dispatch(getSlipNoList())
    },[])
    //for shade family list.
    useEffect(()=>{
		if(shadeFamilyList?.length){
			let newShades = shadeFamilyList?.map((item)=>{
				return {value:item?.id, label:item?.shade_family}
			})
			setShadeFamilyDropdown([...newShades])
		}
	},[shadeFamilyList])
    //
    useEffect(()=>{
		if(dyesNChemicalsList?.length){
			let newDyes = dyesNChemicalsList?.map((item)=>{
				return {value:item?.id, label:item?.dyes_chemical}
			})
			setDyesChemicalDropdown([...newDyes])
		}
	},[dyesNChemicalsList])

    const keyPresent = () =>{
        //return ['yarn_quality_id', 'shade_id', 'lot_id', 'grade'].some((item) => (Object.keys(queryData))?.includes(item))  
        return toShow.some((item) => (Object.keys(queryData))?.includes(item))  
    }

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())


    const BatchDebounced = useDebouncedCallback(
    (value) => {setQueryData(value)},1000
    );
    const MachineDebounced = useDebouncedCallback(
    (value) => {setQueryData(value)},1000
    );
    const DyesChemicalDebounced = useDebouncedCallback(
    (value) => {dispatch(getDyesNChemicalsList(1, 40, {status:'2', name:value}))},1000
    );
    const SlipNoDebounced = useDebouncedCallback(
    (value) => {dispatch(getSlipNoList(value))},1000
    );

    // console.log('slip no', slipNoListData)//
  return (
    <>
    <div className='d-flex justify-content-end align-items-center mb-3'>
    <div>
      <button className={`btn shadow px-4 ${open ? 'btn-danger' : 'btn-light'}`}
      onClick={()=>setOpen(!open)}
      >{open ? <MdClose size={14}/> :<FaFilter/>} Filter</button>
    </div>
    </div>
    {open ? 
    <div className='d-flex gap-3 align-items-center mb-3 custom-filter-div'>
    {toShow?.includes('process_type') &&
    <div>
        <label>Process Type.</label><br></br>
        <Select
        style={{
            width:"200px",
            height:"30px"
        }}
        className={`border rounded border`}
        options={processTypeOptions}
        showSearch
            allowClear
            filterOption={filterOption}
            placeholder='Enter processType'
        onChange={e => {
            let q = {...queryData}
            if(e === undefined){
                delete q.process_type
            }else{
                q.process_type = e;
            }
            
            setQueryData(q)
        }}
        value={queryData?.process_type}
        />
    </div>
    }
    {toShow?.includes('checking_type') &&
    <div>
        <label>Checking Type.</label><br></br>
        <Select
        style={{
            width:"200px",
            height:"30px"
        }}
        className={`border rounded border`}
        options={checkingTypeOptions}
        showSearch
            allowClear
            filterOption={filterOption}
            placeholder='Enter checkingType'
        onChange={e => {
            let q = {...queryData}
            if(e === undefined){
                delete q.checking_type
            }else{
                q.checking_type = e;
            }
            
            setQueryData(q)
        }}
        value={queryData?.checking_type}
        />
    </div>
    }
    {toShow?.includes('job_card_no') &&
    <div>
        <label>Job Card No.</label><br></br>
        <Select
        loading={isJobCardFetching}
        style={{
            width:"200px",
            height:"30px"
        }}
        className={`border rounded border`}
        options={jobCardListData}
        showSearch
        onSearch={(e)=>dispatch(getJobCardList(e))}
            allowClear
            filterOption={filterOption}
            placeholder='Enter jobcard no.'
        onChange={e => {
            let q = {...queryData}
            if(e === undefined){
                delete q.job_card_no
            }else{
                q.job_card_no = e;
            }
            
            setQueryData(q)
        }}
        value={queryData?.job_card_no}
        />
    </div>
    }
    {toShow?.includes('yarn_quality_id') &&
    <div>
        <label>Quality Name</label><br></br>
        <Select
        loading={isQualityFetching}
        style={{
            width:"200px",
            height:"30px"
        }}
        className={`border rounded border`}
        options={qualityListData}
        showSearch
        onSearch={(e)=>dispatch(getQualityList(e))}
            allowClear
            filterOption={filterOption}
            placeholder='Enter quality name...'
        onChange={e => {
            let q = {...queryData}
            if(e === undefined){
                delete q.yarn_quality_id
            }else{
                q.yarn_quality_id = e;
            }
            
            setQueryData(q)
        }}
        value={queryData?.yarn_quality_id}
        />
    </div>
    }
    {toShow?.includes('lot_id') &&
    <div>
        <label>Lot No.</label><br></br>
        <Select
        loading={isLotFetching}
        style={{
            width:"200px",
            height:"30px"
        }}
        className={`border rounded border`}
        options={lotListData}
        showSearch
        onSearch={(e)=>dispatch(getLotList(e))}
            allowClear
            filterOption={filterOption}
            placeholder='Enter lot no.'
            onChange={e => {
                let q = {...queryData}
                if(e === undefined){
                    delete q.lot_id
                }else{
                    q.lot_id = e;
                }
                
                setQueryData(q)
            }}
            value={queryData?.lot_id}
        />
    </div>
    }
    {toShow?.includes('shade_id') &&
    <div>
        <label>Shade No.</label><br></br>
        <Select
        loading={isShadeFetching}
        style={{
            width:"200px",
            height:"30px"
        }}
        className={`border rounded border`}
        options={shadeListData}
        showSearch
        onSearch={(e)=>dispatch(getShadeList(e))}
            allowClear
            filterOption={filterOption}
            placeholder='Enter shade no.'
            onChange={e => {
                let q = {...queryData}
                if(e === undefined){
                    delete q.shade_id
                }else{
                    q.shade_id = e;
                }
                
                setQueryData(q)
            }}
            value={queryData?.shade_id}
        />
    </div>
    }
    {toShow?.includes('shade_nature') &&
    <div>
        <label>Shade Nature.</label><br></br>
        <Select
        style={{
            width:"200px",
            height:"30px"
        }}
        className={`border rounded border`}
        options={shadeNatureOptions}
        showSearch
            allowClear
            filterOption={filterOption}
            placeholder='Enter shade nature.'
            onChange={e => {
                let q = {...queryData}
                if(e === undefined){
                    delete q.shade_nature
                }else{
                    q.shade_nature = e;
                }
                
                setQueryData(q)
            }}
            value={queryData?.shade_nature}
        />
    </div>
    }
     {toShow?.includes('shade_family') &&
    <div>
        <label>Shade Family.</label><br></br>
        <Select
        style={{
            width:"200px",
            height:"30px"
        }}
        className={`border rounded border`}
        options={shadeFamilyDropdown}//{shadeFamilyOptions}
        showSearch
        loading={isFetchingMasterList}
            allowClear
            filterOption={filterOption}
            placeholder='Enter shade family.'
            onChange={e => {
                let q = {...queryData}
                if(e === undefined){
                    delete q.shade_family_id
                }else{
                    q.shade_family_id = e;
                }
                
                setQueryData(q)
            }}
            value={queryData?.shade_family_id}
        />
    </div>
    }
    {toShow?.includes('dyes_chemical_id') &&
    <div>
        <label>Dyes & Chemicals</label><br></br>
        <Select
        style={{
            width:"200px",
            height:"30px"
        }}
        className={`border rounded border`}
        options={dyesChemicalDropdown}
        showSearch
        onSearch={(e)=>DyesChemicalDebounced(e)}
        loading={isFetchingMasterList}
            allowClear
            filterOption={filterOption}
            placeholder='Enter dyes & chemical.'
            onChange={e => {
                let q = {...queryData}
                if(e === undefined){
                    delete q.dyes_chemical_id
                }else{
                    q.dyes_chemical_id = e;
                }
                
                setQueryData(q)
            }}
            value={queryData?.dyes_chemical_id}
        />
    </div>
    }
    {toShow?.includes('grade') &&
    <div>
        <label>Grade</label><br></br>
        <Select
        style={{
            width:"200px",
            height:"30px"
        }}
        className={`border rounded border`}
        options={gradeOptions}
        showSearch
            allowClear
            filterOption={filterOption}
            placeholder='Enter grade...'
            onChange={e => {
                let q = {...queryData}
                if(e === undefined){
                    delete q.grade
                }else{
                    q.grade = e;
                }
                
                setQueryData(q)
            }}
            value={queryData?.grade}
        />
    </div>
    }
    {toShow?.includes('party_id') &&
    <div>
        <label>Party Name</label><br></br>
        <Select
        style={{
            width:"200px",
            height:"30px"
        }}
        className={`border rounded border`}
        options={partyNameListData}
        showSearch
        onSearch={(e)=>dispatch(getPartyNameList(e))}
            allowClear
            filterOption={filterOption}
            placeholder='Enter party name...'
            onChange={e => {
                let q = {...queryData}
                if(e === undefined){
                    delete q.party_id
                }else{
                    q.party_id = e;
                }
                
                setQueryData(q)
            }}
            value={queryData?.party_id}
        />
    </div>
    }
    {toShow?.includes('slip_id') &&
    <div>
        <label>Slip No.</label><br></br>
        <Select
        style={{
            width:"200px",
            height:"30px"
        }}
        className={`border rounded border`}
        options={slipNoListData}
        showSearch
        onSearch={(e)=>SlipNoDebounced(e)}
            allowClear
            filterOption={filterOption}
            placeholder='Enter slip no.'
            onChange={e => {
                let q = {...queryData}
                if(e === undefined){
                    delete q.slip_id
                }else{
                    q.slip_id = e;
                }
                
                setQueryData(q)
            }}
            value={queryData?.slip_id}
        />
    </div>
    }
    {toShow?.includes('batch_no') &&
    <div>
        <label>Batch No..</label><br></br>
        <Input type='text' value={queryData.batch_no}
        style={{
            width:"200px",
            height:"30px"
        }}
        onChange={e => {
            let q = {...queryData}
            if(e.target.value === ''){
                delete q.batch_no
            }else{
                q.batch_no = e.target.value;
            }
            BatchDebounced(q)
        }}
        
        />
    </div>
    }
    {toShow?.includes('machine_no') &&
    <div>
        <label>Machine No..</label><br></br>
        <Input type='text' value={queryData.machine_no}
        style={{
            width:"200px",
            height:"30px"
        }}
        onChange={e => {
            let q = {...queryData}
            if(e.target.value === ''){
                delete q.machine_no
            }else{
                q.machine_no = e.target.value;
            }
            MachineDebounced(q)
        }}
        
        />
    </div>
    }
    {toShow?.includes('plan_date') &&
    <div>
        <label>Plan Date.</label><br></br>
        <Input type='date' value={queryData.plan_date}
        style={{
            width:"200px",
            height:"30px"
        }}
        onChange={e => {
            let q = {...queryData}
            if(e.target.value === ''){
                delete q.plan_date
            }else{
                q.plan_date = e.target.value;
            }
            
            setQueryData(q)
        }}
        
        />
    </div>
    }
    {/* specifically for sale order list */}
    {toShow?.includes('est_delivery_date') &&
    <div>
        <label>Delivery Date.</label><br></br>
        <Input type='date' value={queryData.est_delivery_date}
        style={{
            width:"200px",
            height:"30px"
        }}
        onChange={e => {
            let q = {...queryData}
            if(e.target.value === ''){
                delete q.est_delivery_date
            }else{
                q.est_delivery_date = e.target.value;
            }
            
            setQueryData(q)
        }}
        
        />
    </div>
    }

    {keyPresent() === true ?
    <div>
        <button className='btn btn-danger shadow mt-4' onClick={()=>setQueryData({})}>
        <FaEraser/>&nbsp;Clear
        </button>
    </div> : null}
    </div>
    : null}
    </>
  )
}

export default Filter