import {
    GET_PRINT_DATA,
    GET_DYEING_SALE_PRINT_DATA,
} from '../constants/print'


const initialState = {
    isFetching:false,
    printData: null,
    dyeingPrintData: null,
    error:null,
}

export const printReducer = (state = initialState, { type, payload }) => {
    switch(type){
        case GET_PRINT_DATA.REQUEST:
            return {...state, isFetching:true};
        case GET_PRINT_DATA.SUCCESS:
            return {...state,isFetching:false, printData: payload?.results};
        case GET_PRINT_DATA.FAILURE:
            return {...state, isFetching:false, error:payload};
        case GET_DYEING_SALE_PRINT_DATA.REQUEST:
            return {...state, isFetching:true};
        case GET_DYEING_SALE_PRINT_DATA.SUCCESS:
            return {...state,isFetching:false, dyeingPrintData: payload?.results};
        case GET_DYEING_SALE_PRINT_DATA.FAILURE:
            return {...state, isFetching:false, error:payload};
        default:
            return state;
    }
}