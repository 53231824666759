import { Modal } from "react-bootstrap";
import { reduxForm } from "redux-form";
import { formName } from "./QcIssueToDepartmentForm";

const QcIssueToDepartmentConfirmation = ({ handleSubmit, onCancel }) => {
	return (
		<form onSubmit={handleSubmit}>
			<Modal.Header closeButton>
				<Modal.Title>
					<span style={{ textTransform: "capitalize" }}>Confirmation</span>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h6>Are you sure you want to continue? This action is irreversible.</h6>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-secondary" type="button" onClick={onCancel}>
					Cancel
				</button>
				<button
					className="btn ms-3 align-self-center fs-7 fw-500 bg-color-orange color-white table-btn"
					type="submit"
				>
					Confirm
				</button>
			</Modal.Footer>
		</form>
	);
};

export default reduxForm({
	form: formName,
	destroyOnUnmount: false,
})(QcIssueToDepartmentConfirmation);
