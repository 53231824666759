import { Tooltip } from "antd";
import { Col, Row } from "react-bootstrap";
import { Field } from "redux-form";

import { ReduxFormSelectField } from "utils/ReduxFormSelectField";
import {
	descriptionOptions,
	observationOptions,
	reasonOptions,
} from "../AdditionModal/additionNotOkConstants";

const RenderQcNotOkFields = ({
	errors,
	meta,
	observation,
	description,
	reason,
	remarks,
	isDisable,
}) => {

	const typesOfNotOK = [
		{label:'Redyeing', value:'Redyeing'},
		{label:'Convert', value:'Convert'}
	]
	return (
		<>
			<Row className="mb-3">
				<Col className="mb-1">
					<Field
						component={ReduxFormSelectField}
						name={`not_ok_observation`}
						label="Observation"
						options={observationOptions}
						disabled={reason || description || isDisable}
						touched={meta?.not_ok_observation?.touched}
						error={errors?.not_ok_observation}
					/>
				</Col>
				{observation === "TONAL DIFFRENCE" && (
					<Col className="mb-1">
						<Field
							component={ReduxFormSelectField}
							name={`not_ok_description`}
							label="Description"
							options={descriptionOptions}
							touched={meta?.not_ok_description?.touched}
							error={errors?.not_ok_description}
							disabled={isDisable}
						/>
					</Col>
				)}
				<Col className="mb-1">
					<Field
						component={ReduxFormSelectField}
						name={`not_ok_reason`}
						label="Reason"
						disabled={!observation || remarks || isDisable}
						options={reasonOptions?.filter(ro => ro?.type === observation)}
						touched={meta?.not_ok_reason?.touched}
						error={errors?.not_ok_reason}
					/>
				</Col>
			</Row>
			<Row>
			<Col className="mb-1">
					<Field
						component={ReduxFormSelectField}
						name={`type_of_not_ok`}
						label="Select Not Ok Type"
						disabled={isDisable}
						options={typesOfNotOK}
						touched={meta?.type_of_not_ok?.touched}
						error={errors?.type_of_not_ok}
					/>
				</Col>
			</Row>
			<Row>
				{reason === "M/C PROBLEM" && (
					<Col>
						<Tooltip
							title={
								meta?.not_ok_remarks?.touched && errors?.not_ok_remarks
									? errors?.not_ok_remarks
									: null
							}
						>
							<Field
								component="textarea"
								maxLength={250}
								className={`form-control border border-${
									meta?.not_ok_remarks?.touched && errors?.not_ok_remarks
										? "danger"
										: "secondary"
								}`}
								rows="4"
								name={`not_ok_remarks`}
								placeholder="Enter Remark"
							/>
						</Tooltip>
					</Col>
				)}
			</Row>
		</>
	);
};

export default RenderQcNotOkFields;
