import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
	formValueSelector,
	getFormMeta,
	getFormSyncErrors,
	reduxForm,
} from "redux-form";

import { getIsUpdatingMasterList } from "reducers/master";
import { renderModalButtons } from "utils/renderModalButtons";
import { isRequired } from "utils/validations";
import RenderQcNotOkFields from "./RenderQcNotOkFields";

export const formName = "qcNotOkForm";
const formSelector = formValueSelector(formName);

const QcNotOkForm = ({ onCancel, handleSubmit, isDisable }) => {
	const isUpdatingMaster = useSelector(getIsUpdatingMasterList);
	const errors = useSelector(getFormSyncErrors(formName));
	const meta = useSelector(getFormMeta(formName));

	const observation = useSelector(state =>
		formSelector(state, `not_ok_observation`)
	);
	const description = useSelector(state =>
		formSelector(state, `not_ok_description`)
	);
	const reason = useSelector(state => formSelector(state, `not_ok_reason`));
	const remarks = useSelector(state => formSelector(state, `not_ok_remarks`));

	return (
		<form onSubmit={handleSubmit}>
			<Modal.Header closeButton>
				<Modal.Title>
					<span>Not Ok</span>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<RenderQcNotOkFields
					errors={errors}
					meta={meta}
					observation={observation}
					description={description}
					reason={reason}
					remarks={remarks}
					isDisable={isDisable}
				/>
			</Modal.Body>
			{renderModalButtons(onCancel, isUpdatingMaster,isDisable={isDisable})}
		</form>
	);
};

export default reduxForm({
	form: formName,
	destroyOnUnmount: false,
	enableReinitialize: true,
	keepDirtyOnReinitialize: true,
	validate: values => {
		const errors = {};

		isRequired(values?.not_ok_observation) &&
			(errors.not_ok_observation = "Required");
		isRequired(values?.not_ok_description) &&
			(errors.not_ok_description = "Required");
		isRequired(values?.not_ok_reason) && (errors.not_ok_reason = "Required");
		isRequired(values?.not_ok_remarks) && (errors.not_ok_remarks = "Required");
		isRequired(values?.type_of_not_ok) && (errors.type_of_not_ok = "Required");


		return errors;
	},
})(QcNotOkForm);
