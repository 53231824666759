import { Button, Dropdown } from "antd";
import { MdArrowDropDown } from "react-icons/md";

export const renderActions = (items, isDisabled )=> {
	if (items?.length > 1) {
		return (
			<Dropdown
				menu={{
					items: items.map(item => ({
						label: (
							<Button
								disabled={item?.isEnabled !== undefined ? !item?.isEnabled : false}
								className="d-flex justify-content-center align-items-center p-0 fs-6 w-100"
								type="link"
								onClick={() => item.onClick()}
								style={{
									color: item?.type === "danger" ? "#ca2c33" : "#0d6efd",
								}}
							>
								{item.label}
							</Button>
						),
					})),
				}}
				placement="bottom"
				trigger="click"
			>
				<Button className="m-0 p-0 fs-6" type="link">
					Actions
					<MdArrowDropDown className="fs-5 mb-1" />
				</Button>
			</Dropdown>
		);
	} else if (items?.length === 1) {
		if (
			items?.[0]?.label === "OK" || items?.[0]?.label === "Downgrade JC" || items?.[0]?.label === "Addition" || items?.[0]?.label === "Issue to Soft" || items?.[0]?.label === "Issue to Dispatch" || items?.[0]?.label === "Issue to Department" ||
			items?.[0]?.label === "NOT OK" || items?.[0]?.label === "Create JC" || items?.[0]?.label === "View" || items?.[0]?.label === "Delete" ||
			items?.[0]?.label === "Void" || items?.[0]?.label === "Change Date" || items?.[0]?.label === "Create" || items?.[0]?.label === "Edit" ||
			items?.[0]?.label === "HOLD" || items?.[0]?.label === "Start Production" || items?.[0]?.label === "End Production" || items?.[0]?.label === "Print" ||
			items?.[0]?.label === "Create WIP" || items?.[0]?.label === "Create Downgrade JC" || items?.[0]?.label === "Create Production" || items?.[0]?.label === "Issue to RM" 
			|| items?.[0]?.label === "Move To QC" || items?.[0]?.label === "Rewinding" || items?.[0]?.label === "Production To Other Process" || items?.[0]?.label === "Update QC Details" || items?.[0]?.label === "Move To Packing" ||
			items?.[0]?.label === "Create Cartons" || items?.[0]?.label === "Redyeing Plan" || items?.[0]?.label === "Generate Invoice" || items?.[0]?.label === "Change Order No." ||
			items?.[0]?.label === "Print Big Challan" || items?.[0]?.label === "Print Small Challan" || items?.[0]?.label === "Continue Production" || items?.[0]?.label === "Issue Sample to Downgrade"
		) {
			return (
				<Button
					disabled={isDisabled}
					className="d-flex justify-content-start align-items-center p-0 fs-6 w-100"
					type="link"
					onClick={() => items?.[0]?.onClick()}
					style={{
						color:
							items?.[0]?.type === "danger"
								? "#ca2c33"
								: items?.[0]?.type === "plain"
									? "#000000"
									: "#0d6efd",
					}}
				>
					{items?.[0]?.label}
				</Button>
			);
		} else {
			return <p>{items?.[0]?.label}</p>;
		}
	}
};
