import { reducer as formReducer } from "redux-form";
import { masterReducer } from "./master";
import { modalReducer } from "./modal";
import { userReducer } from "./user";
import { heatSettingReducer } from './heatSetting'
import { dyeingDowngradeReducer } from "./dyeing";
import { softDeptReducer } from "./softDept";
import { windingReducer } from "./winding";
import { packingReducer } from "./packing";
import { filterReducer } from "./filter";
import { printReducer } from "./print";

export const rootReducers = {
	masterDetails: masterReducer,
	userDetails: userReducer,
	modals: modalReducer,
	form: formReducer,
	heatSetting: heatSettingReducer,
	dyeing: dyeingDowngradeReducer,
	softDept: softDeptReducer,
	winding: windingReducer,
	packing: packingReducer,
	filter: filterReducer,
	print: printReducer,
};
