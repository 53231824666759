import axios from 'axios';
import {
    GET_QUALITY_LIST,
    GET_SHADE_LIST,
    GET_LOT_LIST,
    GET_JOB_CARD_LIST,
    GET_PARTY_NAME_LIST,
    GET_SLIP_NO_LIST,
    GET_INVOICE_LIST
} from '../constants/filter'
import stringifyQueryParams from "utils/stringifyQueryParams";
import apiConfig from "./apiConfig";

export const getQualityList = (name=null,type) => async(dispatch, getState)=>{
    try{
        dispatch({type: GET_QUALITY_LIST.REQUEST})
        const { userId } = getState().userDetails;
        const config = { headers: apiConfig?.getHeaders() };
        let q;
        if (type === "multi"){
            q = name !== null ? `${apiConfig?.baseURL}/v1/user/${userId}/yarn-quality/list?dropdown=3&status=2&sort=id&name=${name}`
                    : `${apiConfig?.baseURL}/v1/user/${userId}/yarn-quality/list?dropdown=3&status=2&sort=id`
        }else{
            q = name !== null ? `${apiConfig?.baseURL}/v1/user/${userId}/yarn-quality/list?dropdown=1&status=2&sort=id&name=${name}`
                    : `${apiConfig?.baseURL}/v1/user/${userId}/yarn-quality/list?dropdown=1&status=2&sort=id`
        }
        const { data } = await axios.get(
            q,
            config
        );
        dispatch({
            type: GET_QUALITY_LIST.SUCCESS,
            payload: data?.data
        });

    }catch(error){
        dispatch({ type: GET_QUALITY_LIST.FAILURE, payload: error });
    }
}

export const getLotList = (name=null,type) => async(dispatch, getState)=>{
    try{
        dispatch({type: GET_LOT_LIST.REQUEST})
        const { userId } = getState().userDetails;
        const config = { headers: apiConfig?.getHeaders() };
        let q;
        if (type === "multi"){
            q = name !== null ? `${apiConfig?.baseURL}/v1/user/${userId}/lot/list?dropdown=3&status=2&sort=id&name=${name}`
        : `${apiConfig?.baseURL}/v1/user/${userId}/lot/list?dropdown=3&status=2&sort=id`
        }else{
            q = name !== null ? `${apiConfig?.baseURL}/v1/user/${userId}/lot/list?dropdown=1&status=2&sort=id&name=${name}`
        : `${apiConfig?.baseURL}/v1/user/${userId}/lot/list?dropdown=1&status=2&sort=id`
        }
        

        const { data } = await axios.get(
            q,
            config
        );
        dispatch({
            type: GET_LOT_LIST.SUCCESS,
            payload: data?.data
        });

    }catch(error){
        dispatch({ type: GET_LOT_LIST.FAILURE, payload: error });
    }
}

export const getShadeList = (name=null,type) => async(dispatch, getState)=>{
    try{
        dispatch({type: GET_SHADE_LIST.REQUEST})
        const { userId } = getState().userDetails;
        const config = { headers: apiConfig?.getHeaders() };
        let q;
        if (type==="multi"){
            q = name !== null ?`${apiConfig?.baseURL}/v1/user/${userId}/shade/list?dropdown=3&status=2&sort=id&name=${name}`
        : `${apiConfig?.baseURL}/v1/user/${userId}/shade/list?dropdown=3&status=2&sort=id`
        }else{
            q = name !== null ?`${apiConfig?.baseURL}/v1/user/${userId}/shade/list?dropdown=1&status=2&sort=id&name=${name}`
        : `${apiConfig?.baseURL}/v1/user/${userId}/shade/list?dropdown=1&status=2&sort=id`
        }

        const { data } = await axios.get(
            q,
            config
        );
        dispatch({
            type: GET_SHADE_LIST.SUCCESS,
            payload: data?.data
        });

    }catch(error){
        dispatch({ type: GET_SHADE_LIST.FAILURE, payload: error });
    }
}

export const getJobCardList = (name=null) => async(dispatch, getState)=>{
    try{
        dispatch({type: GET_JOB_CARD_LIST.REQUEST})
        const { userId } = getState().userDetails;
        const config = { headers: apiConfig?.getHeaders() };
        let q = name !== null ?`${apiConfig?.baseURL}/v1/user/${userId}/dyeing/jobcard/list?name=${name}`
        : `${apiConfig?.baseURL}/v1/user/${userId}/dyeing/jobcard/list`

        const { data } = await axios.get(
            q,
            config
        );
        dispatch({
            type: GET_JOB_CARD_LIST.SUCCESS,
            payload: data?.data
        });

    }catch(error){
        dispatch({ type: GET_JOB_CARD_LIST.FAILURE, payload: error });
    }
}

export const getPartyNameList = (name=null,type) => async(dispatch, getState)=>{
    try{
        dispatch({type: GET_PARTY_NAME_LIST.REQUEST})
        const { userId } = getState().userDetails;
        const config = { headers: apiConfig?.getHeaders() };
        let q;
        if (type === "multi"){
            q = name !== null ?`${apiConfig?.baseURL}/v1/user/${userId}/party/list?dropdown=3&status=2&sort=id&name=${name}`
                    : `${apiConfig?.baseURL}/v1/user/${userId}/party/list?dropdown=3&status=2&sort=id`
        }else{
            q = name !== null ?`${apiConfig?.baseURL}/v1/user/${userId}/party/list?party_type=customer&dropdown=1&status=2&sort=id&name=${name}`
        : `${apiConfig?.baseURL}/v1/user/${userId}/party/list?party_type=customer&dropdown=1&status=2&sort=id`
        }
        const { data } = await axios.get(
            q,
            config
        );
        dispatch({
            type: GET_PARTY_NAME_LIST.SUCCESS,
            payload: data?.data
        });

    }catch(error){
        dispatch({ type: GET_PARTY_NAME_LIST.FAILURE, payload: error });
    }
}

export const getDyesPercentageValue = (id, dyes_chemical_id, add_percent, addition) => async(dispatch, getState)=>{
    try{
        const { userId } = getState().userDetails;
        const config = { headers: apiConfig?.getHeaders() };

        const { data } = await axios.get(
            `${apiConfig?.baseURL}/v1/user/${userId}/dyeing-production/dyes-calculation?id=${id}&dyes_chemical_id=${dyes_chemical_id}&addition_percent=${add_percent}&addition_count=${addition}`,
            config
        );
        return data?.data

    }catch(error){
        dispatch({ type: GET_PARTY_NAME_LIST.FAILURE, payload: error });
    }
}

export const getSlipNoList = (name=null) => async(dispatch, getState)=>{
    try{
        dispatch({type: GET_SLIP_NO_LIST.REQUEST})
        const { userId } = getState().userDetails;
        const config = { headers: apiConfig?.getHeaders() };
        let q = name !== null ?`${apiConfig?.baseURL}/v1/user/${userId}/issue-to-department/list?dropdown=1&name=${name}`
        : `${apiConfig?.baseURL}/v1/user/${userId}/issue-to-department/list?dropdown=1`

        const { data } = await axios.get(
            q,
            config
        );
        dispatch({
            type: GET_SLIP_NO_LIST.SUCCESS,
            payload: data?.data
        });

    }catch(error){
        dispatch({ type: GET_SLIP_NO_LIST.FAILURE, payload: error });
    }
}

export const getInvoiceNoList = (name=null) => async(dispatch, getState)=>{
    try{
        dispatch({type: GET_INVOICE_LIST.REQUEST})
        const { userId } = getState().userDetails;
        const config = { headers: apiConfig?.getHeaders() };
        let q = name !== null ?`${apiConfig?.baseURL}/v1/user/${userId}/dispatch/invoice/list?dropdown=1&name=${name}`
        : `${apiConfig?.baseURL}/v1/user/${userId}/dispatch/invoice/list?dropdown=1`

        const { data } = await axios.get(
            q,
            config
        );
        dispatch({
            type: GET_INVOICE_LIST.SUCCESS,
            payload: data?.data
        });

    }catch(error){
        dispatch({ type: GET_INVOICE_LIST.FAILURE, payload: error });
    }
}