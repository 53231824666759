import axios from "axios";

import {
	ADD_MASTER_LIST,
	EDIT_MASTER_LIST,
	EXPORT_MASTER,
	GET_MASTER_DETAILS,
	GET_MASTER_LIST,
	IMPORT_MASTER,
	RESET_MASTER_DETAILS,
	SHADE_FAMILY,
	DYES_AND_CHEMICALS,
	RECIPE,
	PROGRAM,
} from "constants/master";
import stringifyQueryParams from "utils/stringifyQueryParams";

import apiConfig from "./apiConfig";
import { addNotification } from "./notifications";

export const getMasterList =
	(masterName, query = {}, downgrade=false,type) =>
	async (dispatch, getState) => {
		try {
			const queryString = stringifyQueryParams(query);
			dispatch({ type: GET_MASTER_LIST.REQUEST });

			const { userId } = getState().userDetails;
			const config = { headers: apiConfig?.getHeaders() };

			let q = downgrade === true ? `${apiConfig?.baseURL}/v1/user/${userId}/dyeing-downgrade/list?${queryString}`:
			`${apiConfig?.baseURL}/v1/user/${userId}/${masterName}/list?${queryString}`;

			const { data } = await axios.get(
				q,
				config
			);
			
			//in case of merged data fetched form api
			let tempData = data;
			function ObjectEqual(obj1, obj2, prop) {
				return obj1[prop] === obj2[prop];
			  }
			  function getUniqueObjects(array, prop) {
				return array.filter((value, index, self) => {
				  return self.findIndex(obj => ObjectEqual(obj, value, prop)) === index;
				});
			  }
			if(masterName === 'dyeing-planning'){
				tempData?.data?.results.forEach(entry => {
				 if (entry.entry_type === "Merged" || entry.entry_type === "Normal" || entry.entry_type === "Redyeing" || entry.entry_type === "Redyeing_Slip" || entry.entry_type === "Convert" || entry.entry_type === "Mix Lot") {
					entry.order_no = entry?.sale_order?.map(order => order?.order_no)
					entry.order_no = [...new Set(entry.order_no)]?.join(', ')
					entry.party_name = entry?.sale_order?.map(order => order?.party_name)
					entry.party_name = [...new Set(entry.party_name)]?.join(', ')
					entry.process_name = entry?.sale_order?.map(order => order?.process_name)
					entry.process_name = [...new Set(entry.process_name)]?.join(', ')
					entry.yarn_quality = entry?.sale_order?.map(order => order?.yarn_quality)
					entry.yarn_quality = getUniqueObjects(entry.yarn_quality, 'id');
					entry.yarn_quality = entry.yarn_quality?.length === 1 ?  entry.yarn_quality[0] : entry.yarn_quality?.map(order => order?.name).join(', ');
					entry.shade = entry?.sale_order?.[0]?.shade;
					entry.order_date = entry?.sale_order?.[0]?.order_date;
				}
				  })

			}
			//end merged logic.
			dispatch({
				type: GET_MASTER_LIST.SUCCESS,
				payload: {
					success: "success",
					data: masterName === 'dyeing-planning' ? tempData : data,
					masterName,
					query,
				},
			});
		} catch (error) {
			dispatch({ type: GET_MASTER_LIST.FAILURE, payload: error });
		}
	};

export const getMasterDetails =
	(masterName, id) => async (dispatch, getState) => {
		try {
			dispatch({ type: GET_MASTER_DETAILS.REQUEST });

			const { userId } = getState().userDetails;
			const config = { headers: apiConfig?.getHeaders() };

			const { data } = await axios.get(
				`${apiConfig?.baseURL}/v1/user/${userId}/${masterName}/list?id=${id}`,
				config
			);

			dispatch({
				type: GET_MASTER_DETAILS.SUCCESS,
				payload: {
					success: "success",
					data,
					masterName,
				},
			});
		} catch (error) {
			dispatch({ type: GET_MASTER_DETAILS.FAILURE, payload: error });
		}
	};

export const resetMasterDetails = masterName => async dispatch => {
	dispatch({ type: RESET_MASTER_DETAILS, payload: { masterName } });
};

export const addMasterList =
	(
		masterName,
		masterData,
		status = null,
		configType = "application/json",
		navigationFunction = null,
		options = null,
	) =>
	async (dispatch, getState) => {
		try {
			dispatch({ type: ADD_MASTER_LIST.REQUEST });

			const { userId } = getState().userDetails;
			const config = {
				headers: {
					...apiConfig?.getHeaders(),
					"Content-Type": configType,
				},
			};
			let q = options?.includes('?') ? options : ''

			const { data } = await axios.post(
				`${apiConfig?.baseURL}/v1/user/${userId}/${masterName}/create${q}`,
				masterData,
				config
			);

			if (navigationFunction) navigationFunction();

			if(masterName.includes('/merged')){
			masterName = masterName.split('/')
			let m = masterName[0]
			masterName = m
			}

			dispatch({
				type: ADD_MASTER_LIST.SUCCESS,
				payload: {
					success: "success",
					data,
					status,
					masterName,
					navigated: !!navigationFunction,
				},
			});
			dispatch(
				addNotification({ message: data?.data?.message, type: "success" })
			);
			setTimeout(()=>{
				window.location.reload()
			},1000)
		} catch (error) {
			dispatch({ type: ADD_MASTER_LIST.FAILURE, payload: error });
		}
	};

export const editMasterList =
	(
		masterName,
		masterData,
		status = null,
		configType = "application/json",
		navigationFunction = null,
		fromItd=false,
	) =>
	async (dispatch, getState) => {
		try {
			if(!masterData?.type_of_not_ok) masterData.type_of_not_ok = null;
			dispatch({ type: EDIT_MASTER_LIST.REQUEST });

			const { userId } = getState().userDetails;
			const config = {
				headers: {
					...apiConfig?.getHeaders(),
					"Content-Type": configType,
				},
			};

			let q = fromItd === true ? 
			`${apiConfig?.baseURL}/v1/user/${userId}/dyeing-itd/update-production/create`
			:`${apiConfig?.baseURL}/v1/user/${userId}/${masterName}/update`;

			const { data } = await axios.post(
				q,
				masterData,
				config
			);

			if (navigationFunction) navigationFunction();

			dispatch({
				type: EDIT_MASTER_LIST.SUCCESS,
				payload: {
					success: "success",
					data,
					status,
					masterName,
					navigated: !!navigationFunction,
				},
			});

			dispatch(
				addNotification({ message: data?.data?.message, type: "success" })
			);
		} catch (error) {
			dispatch({ type: EDIT_MASTER_LIST.FAILURE, payload: error });
		}
	};

export const exportMasterList =
	(master, status = null) =>
	async (dispatch, getState) => {
		try {
			dispatch({ type: EXPORT_MASTER.REQUEST });

			const { userId } = getState().userDetails;
			const config = { headers: apiConfig?.getHeaders() };

			const { data } = await axios.post(
				`${
					apiConfig?.baseURL
				}/user/${userId}/bulk/export/master?status=${+status}`,
				{ master },
				config
			);
			let a = document.createElement("a");
			a.href = data?.data?.url;
			a.target = "_blank";
			a.click();

			dispatch({
				type: EXPORT_MASTER.SUCCESS,
				payload: {
					success: "success",
				},
			});
		} catch (error) {
			dispatch({ type: EXPORT_MASTER.FAILURE, payload: error });
		}
	};

export const importMaster =
	(formData, masterName, status) => async (dispatch, getState) => {
		try {
			dispatch({ type: IMPORT_MASTER.REQUEST });

			const { userId } = getState().userDetails;
			const config = {
				headers: {
					...apiConfig?.getHeaders(),
					"Content-Type": "multipart/form-data",
				},
			};

			const { data } = await axios.post(
				`${apiConfig?.baseURL}/user/${userId}/bulk/upload/master`,
				formData,
				config
			);

			if (!data?.data?.url) {
				dispatch(
					addNotification({ message: data?.data?.message, type: "success" })
				);
			} else {
				let a = document.createElement("a");
				a.href = data?.data?.url;
				a.click();
				dispatch(
					addNotification({ message: data?.data?.message, type: "error" })
				);
			}

			dispatch({
				type: IMPORT_MASTER.SUCCESS,
				payload: {
					success: "success",
					masterName,
					status,
				},
			});
		} catch (error) {
			dispatch({ type: IMPORT_MASTER.FAILURE, payload: error });
		}
	};

	export const getShadeFamilyList = (pageNum=1, itemsPerPage=40, query={}) => async(dispatch, getState)=>{
        try{
            let qry = query
            qry.page = pageNum
            qry.page_size = itemsPerPage
            const queryString = stringifyQueryParams(qry);
            dispatch({type: SHADE_FAMILY.REQUEST})
            const { userId } = getState().userDetails;
			const config = { headers: apiConfig?.getHeaders() };

            const { data } = await axios.get(
				`${apiConfig?.baseURL}/v1/user/${userId}/shade-family/list?${queryString}`,
				config
			);
            // return console.log('head data', data)
            if(data?.data) data.data.page = pageNum;
            dispatch({
				type: SHADE_FAMILY.SUCCESS,
				payload: data?.data
			});

        }catch(error){
            dispatch({ type: SHADE_FAMILY.FAILURE, payload: error });
        }
}

export const AddUpdateShadeFamily = (formData, update=false) => async(dispatch, getState)=>{
    try{
        dispatch({type: SHADE_FAMILY.REQUEST})
        const { userId } = getState().userDetails;
        const config = { headers: apiConfig?.getHeaders() };

		let q = update === true ? 
		`${apiConfig?.baseURL}/v1/user/${userId}/shade-family/update`
		:
		`${apiConfig?.baseURL}/v1/user/${userId}/shade-family/create`;

        const { data } = await axios.post(
            q,
            formData,
            config
        );
        dispatch(
            addNotification({ message: data?.data?.[0] || data?.data?.message, type: "success" })
        );
    }catch(error){
        dispatch({ type: SHADE_FAMILY.FAILURE, payload: error });
    }
}


export const getDyesNChemicalsList = (pageNum=1, itemsPerPage=40, query={}) => async(dispatch, getState)=>{
        try{
            let qry = query
            qry.page = pageNum
            qry.page_size = itemsPerPage
            const queryString = stringifyQueryParams(qry);
            dispatch({type: DYES_AND_CHEMICALS.REQUEST})
            const { userId } = getState().userDetails;
			const config = { headers: apiConfig?.getHeaders() };

            const { data } = await axios.get(
				`${apiConfig?.baseURL}/v1/user/${userId}/dyes-chemicals/list?${queryString}`,
				config
			);
            // return console.log('head data', data)
            if(data?.data) data.data.page = pageNum;
            dispatch({
				type: DYES_AND_CHEMICALS.SUCCESS,
				payload: data?.data
			});

        }catch(error){
            dispatch({ type: DYES_AND_CHEMICALS.FAILURE, payload: error });
        }
}

export const AddUpdateDyesNChemicals = (formData, update=false) => async(dispatch, getState)=>{
    try{
        dispatch({type: DYES_AND_CHEMICALS.REQUEST})
        const { userId } = getState().userDetails;
        const config = { headers: apiConfig?.getHeaders() };

		let q = update === true ? 
		`${apiConfig?.baseURL}/v1/user/${userId}/dyes-chemicals/update`
		:
		`${apiConfig?.baseURL}/v1/user/${userId}/dyes-chemicals/create`;

        const { data } = await axios.post(
            q,
            formData,
            config
        );
        dispatch(
            addNotification({ message: data?.data?.[0] || data?.data?.message, type: "success" })
        );
    }catch(error){
        dispatch({ type: DYES_AND_CHEMICALS.FAILURE, payload: error });
    }
}

export const getRecipeList = (pageNum=1, itemsPerPage=40, query={}) => async(dispatch, getState)=>{
	try{
		let qry = query
		qry.page = pageNum
		qry.page_size = itemsPerPage
		const queryString = stringifyQueryParams(qry);
		dispatch({type: RECIPE.REQUEST})
		const { userId } = getState().userDetails;
		const config = { headers: apiConfig?.getHeaders() };

		const { data } = await axios.get(
			`${apiConfig?.baseURL}/v1/user/${userId}/receipe/list?${queryString}`,
			config
		);
		// return console.log('head data', data)
		if(data?.data) data.data.page = pageNum;
		dispatch({
			type: RECIPE.SUCCESS,
			payload: data?.data
		});

	}catch(error){
		dispatch({ type: RECIPE.FAILURE, payload: error });
	}
}

export const AddUpdateRecipe = (formData, update=false) => async(dispatch, getState)=>{
try{
	dispatch({type: RECIPE.REQUEST})
	const { userId } = getState().userDetails;
	const config = { headers: apiConfig?.getHeaders() };

	let q = update === true ? 
	`${apiConfig?.baseURL}/v1/user/${userId}/receipe/update`
	:
	`${apiConfig?.baseURL}/v1/user/${userId}/receipe/create`;

	const { data } = await axios.post(
		q,
		formData,
		config
	);
	dispatch(
		addNotification({ message: data?.data?.[0] || data?.data?.message, type: "success" })
	);
}catch(error){
	dispatch({ type: RECIPE.FAILURE, payload: error });
}
}

export const getProgramList = (pageNum=1, itemsPerPage=40, query={}) => async(dispatch, getState)=>{
	try{
		let qry = query
		qry.page = pageNum
		qry.page_size = itemsPerPage
		const queryString = stringifyQueryParams(qry);
		dispatch({type: PROGRAM.REQUEST})
		const { userId } = getState().userDetails;
		const config = { headers: apiConfig?.getHeaders() };

		const { data } = await axios.get(
			`${apiConfig?.baseURL}/v1/user/${userId}/program/list?${queryString}`,
			config
		);

		if(data?.data) data.data.page = pageNum;
		dispatch({
			type: PROGRAM.SUCCESS,
			payload: data?.data
		});

	}catch(error){
		dispatch({ type: PROGRAM.FAILURE, payload: error });
	}
}

export const AddUpdateProgram = (formData, update=false) => async(dispatch, getState)=>{
try{
	dispatch({type: PROGRAM.REQUEST})
	const { userId } = getState().userDetails;
	const config = { headers: apiConfig?.getHeaders() };

	let q = update === true ? 
	`${apiConfig?.baseURL}/v1/user/${userId}/program/update`
	:
	`${apiConfig?.baseURL}/v1/user/${userId}/program/create`;

	const { data } = await axios.post(
		q,
		formData,
		config
	);
	dispatch(
		addNotification({ message: data?.data?.[0] || data?.data?.message, type: "success" })
	);
}catch(error){
	dispatch({ type: PROGRAM.FAILURE, payload: error });
}
}


export const importFiles =
	(masterName, status) => async (dispatch, getState) => {
		try {
			dispatch({ type: IMPORT_MASTER.REQUEST });

			const { userId } = getState().userDetails;
			const config = {
				headers: {
					...apiConfig?.getHeaders(),
					"Content-Type": "multipart/form-data",
				},
			};

			const { data } = await axios.post(
				`${apiConfig?.baseURL}/user/${userId}/bulk/export/dyeing-production?status=${status}`,
				{},
				config
			);

			if (!data?.data?.url) {
				dispatch(
					addNotification({ message: data?.data?.message, type: "success" })
				);
			} else {
				let a = document.createElement("a");
				a.href = data?.data?.url;
				a.click();
				dispatch(
					addNotification({ message: data?.data?.message, type: "success" })
				);
			}

			dispatch({
				type: IMPORT_MASTER.SUCCESS,
				payload: {
					success: "success",
					masterName,
					status,
				},
			});
		} catch (error) {
			dispatch({ type: IMPORT_MASTER.FAILURE, payload: error });
		}
	};


	export const saleOrderExportToExcel =
	(query={}) =>
	async (dispatch, getState) => {
		try {
			dispatch({ type: EXPORT_MASTER.REQUEST });

			const { userId } = getState().userDetails;
			const config = { headers: apiConfig?.getHeaders() };
			const queryString = stringifyQueryParams(query);
			const { data } = await axios.post(
				`${
					apiConfig?.baseURL
				}/user/${userId}/bulk/export/sale-order?${queryString}`,
				{},
				config
			);
			let a = document.createElement("a");
			a.href = data?.data?.url;
			a.target = "_blank";
			a.click();

			dispatch({
				type: EXPORT_MASTER.SUCCESS,
				payload: {
					success: "success",
				},
			});
		} catch (error) {
			dispatch({ type: EXPORT_MASTER.FAILURE, payload: error });
		}
	};


	export const invoiceExportToExcel =
	(body) =>
	async (dispatch, getState) => {
		try {
			dispatch({ type: EXPORT_MASTER.REQUEST });

			const { userId } = getState().userDetails;
			const config = { headers: apiConfig?.getHeaders() };
			const { data } = await axios.post(
				`${
					apiConfig?.baseURL
				}/user/${userId}/bulk/export/dispatch-stock`,
				body,
				config
			);
			let a = document.createElement("a");
			a.href = data?.data?.url;
			a.target = "_blank";
			a.click();

			dispatch({
				type: EXPORT_MASTER.SUCCESS,
				payload: {
					success: "success",
				},
			});
			return data;
		} catch (error) {
			dispatch({ type: EXPORT_MASTER.FAILURE, payload: error });
		}
	};

	export const cartonExportToExcel =
	(body) =>
	async (dispatch, getState) => {
		try {
			dispatch({ type: EXPORT_MASTER.REQUEST });

			const { userId } = getState().userDetails;
			const config = { headers: apiConfig?.getHeaders() };
			const { data } = await axios.post(
				`${
					apiConfig?.baseURL
				}/user/${userId}/bulk/export/carton-stock`,
				body,
				config
			);
			let a = document.createElement("a");
			a.href = data?.data?.url;
			a.target = "_blank";
			if(data?.data !== null) a.click();
			// a.click();

			dispatch({
				type: EXPORT_MASTER.SUCCESS,
				payload: {
					success: "success",
				},
			});
		} catch (error) {
			dispatch({ type: EXPORT_MASTER.FAILURE, payload: error });
		}
	};
	export const dyeingPendingExportToExcel =
	(body) =>
	async (dispatch, getState) => {
		try {
			dispatch({ type: EXPORT_MASTER.REQUEST });

			const { userId } = getState().userDetails;
			const config = { headers: apiConfig?.getHeaders() };
			const { data } = await axios.post(
				`${
					apiConfig?.baseURL
				}/user/${userId}/bulk/export/dyeing-pending`,
				body,
				config
			);
			let a = document.createElement("a");
			a.href = data?.data?.url;
			a.target = "_blank";
			a.download =  `dyeing-pending-${new Date().toLocaleString()}`;
			if(data?.data?.url !== null) a.click();
			// a.click();

			dispatch({
				type: EXPORT_MASTER.SUCCESS,
				payload: {
					success: "success",
				},
			});
		} catch (error) {
			dispatch({ type: EXPORT_MASTER.FAILURE, payload: error });
		}
	};

	export const rmReportsExportToExcel =
	(body) =>
	async (dispatch, getState) => {
		try {
			dispatch({ type: EXPORT_MASTER.REQUEST });

			const { userId } = getState().userDetails;
			const config = { headers: apiConfig?.getHeaders() };
			const { data } = await axios.post(
				`${
					apiConfig?.baseURL
				}/user/${userId}/bulk/export/raw-material/reports`,
				body,
				config
			);
			let a = document.createElement("a");
			a.href = data?.data?.url;
			a.target = "_blank";
			a.download =  `dyeing-pending-${new Date().toLocaleString()}`;
			if(data?.data?.url !== null) a.click();
			// a.click();

			dispatch({
				type: EXPORT_MASTER.SUCCESS,
				payload: {
					success: "success",
				},
			});
		} catch (error) {
			dispatch({ type: EXPORT_MASTER.FAILURE, payload: error });
		}
	};