import { Tooltip } from "antd";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getIsUpdatingMasterList } from "reducers/master";
import {
	Field,
	change,
	formValueSelector,
	getFormMeta,
	getFormSyncErrors,
	reduxForm,
} from "redux-form";
import { ReduxFormSelectField } from "utils/ReduxFormSelectField";
import { ReduxFormTextField } from "utils/ReduxFormTextField";
import { renderModalButtons } from "utils/renderModalButtons";
import { isRequired } from "utils/validations";

export const formName = "qcItdForm";
const formSelector = formValueSelector(formName);
//
const itdOptions = [
	{ label: "Winding Department", value: "Winding Department" },
	{ label: "Dispatch Department", value: "Dispatch Department" },
	{ label: "Winding QC Department", value: "Winding QC Department" },
];
const processTypeOptions = [
	{value:'PCW', label:'PCW'},
	{value:'Y-Cone', label:'Y-Cone'},
	{value:'A-Cone', label:'A-Cone'},
	{value:'Pineapple', label:'Pineapple'},
]

const QcIssueToDepartmentForm = ({ onCancel, handleSubmit, initialValues }) => {

	const dispatch = useDispatch();

	const isUpdatingMaster = useSelector(getIsUpdatingMasterList);
	const errors = useSelector(getFormSyncErrors(formName));
	const meta = useSelector(getFormMeta(formName));

	const totalCheese = useSelector(state => formSelector(state, `total_cheese`));
	const acceptedCheese = useSelector(state => formSelector(state, `accepted_cheese`));
	const totalNetWeight = useSelector(state =>
		formSelector(state, `total_net_weight`)
	);

	const averageWeight = useSelector(state =>
		formSelector(state, `average_net_weight`)
	);
	const rejectedCheese = useSelector(state =>
		formSelector(state, `rejected_cheese`)
	);
	const missingCheese = useSelector(state =>
		formSelector(state, `missing_cheese`)
	);
	const sampleCheese = useSelector(state =>
		formSelector(state, `sample_cheese`)
	);
	const netBatchWeight = useSelector(state =>
		formSelector(state, `final_cheese_net_weight`)
	);
	const conditioningWeight = useSelector(state =>
		formSelector(state, `conditioning_weight`)
	);
	const issueToDepartment = useSelector(state =>
		formSelector(state, `issue_to_department`)
	);

	useEffect(()=>{
		dispatch(change(formName, "rejected_cheese", 0))
		dispatch(change(formName, "missing_cheese", 0))
		dispatch(change(formName, "sample_cheese", 0))
		dispatch(change(formName, "issue_date", moment().format("YYYY-MM-DD")))
	},[])

	useEffect(() => {
		dispatch(
			change(
				formName,
				"average_net_weight",
				(totalNetWeight / totalCheese).toFixed(4) || 0
			)
		);
	}, [totalCheese, totalNetWeight, dispatch]);

	useEffect(() => {
		dispatch(
			change(
				formName,
				"rejected_net_weight",
				(averageWeight * rejectedCheese).toFixed(4) || 0
			)
		);
	}, [rejectedCheese, averageWeight, dispatch]);

	useEffect(() => {
		dispatch(
			change(
				formName,
				"missing_net_weight",
				(averageWeight * missingCheese).toFixed(4) || 0
			)
		);
	}, [missingCheese, averageWeight, dispatch]);

	useEffect(() => {
		dispatch(
			change(
				formName,
				"sample_net_weight",
				(averageWeight * sampleCheese).toFixed(4) || 0
			)
		);
	}, [sampleCheese, averageWeight, dispatch]);

	useEffect(() => {
		dispatch(
			change(
				formName,
				"no_of_final_cheeses",
				acceptedCheese - rejectedCheese - missingCheese - sampleCheese
			)
		);
		dispatch(
			change(
				formName,
				"final_cheese_net_weight",
				(
					(acceptedCheese - rejectedCheese - missingCheese - sampleCheese) *
					averageWeight
				).toFixed(4) || 0
			)
		);
	}, [
		acceptedCheese,
		rejectedCheese,
		missingCheese,
		sampleCheese,
		averageWeight,
		dispatch,
	]);

	useEffect(() => {
		const differenceWeight = conditioningWeight - netBatchWeight;
		dispatch(
			change(formName, "difference_weight", differenceWeight.toFixed(4) || 0)
		);
		dispatch(
			change(
				formName,
				"moisture_percent",
				((differenceWeight / netBatchWeight) * 100).toFixed(2) || 0
			)
		);
	}, [conditioningWeight, netBatchWeight, dispatch]);

	const [showProcessType, setShowProcessType] = useState(false)

	return (
		<form onSubmit={handleSubmit}>
			<Modal.Header closeButton>
				<Modal.Title>
					<span>Issue To Department</span>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col className="mb-1">
						<Field
							component={ReduxFormTextField}
							type="date"
							name="issue_date"
							label="Issue Date"
							touched={meta?.issue_date?.touched}
							error={errors?.issue_date}
						/>
					</Col>
					<Col className="mb-1">
						<Field
							component={ReduxFormSelectField}
							name="issue_to_department"
							label="Issue To Department"
							onChange={e => e === 'Winding Department' ? setShowProcessType(true) : setShowProcessType(false)}
							options={initialValues?.winding_issue === true ? itdOptions : itdOptions?.filter((x)=> x?.value !== "Winding Department")}
							touched={meta?.issue_to_department?.touched}
							error={errors?.issue_to_department}
						/>
					</Col>
					{issueToDepartment === "Dispatch Department" &&
						<Col className="mb-1">
						<Field
							label="Total Cartons"
							name="total_cartons"
							component={ReduxFormTextField}
							type="number"
							step={1}
							min={0}
						/>
					</Col>
					}
					{showProcessType === true &&
					<Col className="mb-1">
						<Field
							component={ReduxFormSelectField}
							name="process_type"
							label="Process Type"
							options={processTypeOptions}
							touched={meta?.process_type?.touched}
							error={errors?.process_type}
						/>
					</Col>
					}
					<Col className="mb-1">
						<Field
							label="No of Cheese"
							name="accepted_cheese"
							component={ReduxFormTextField}
							type="number"
							disabled
						/>
					</Col>
					<Col className="mb-1">
						<Field
							label="Net Weight"
							name="accepted_net_weight"
							component={ReduxFormTextField}
							type="number"
							disabled
						/>
					</Col>
					<Col className="mb-1">
						<Field
							label="Average Weight"
							name="average_net_weight"
							component={ReduxFormTextField}
							type="number"
							disabled
						/>
					</Col>
				</Row>
				<Row>
					<Col className="mb-1">
						<Field
							label="Rejected Cheese"
							name="rejected_cheese"
							component={ReduxFormTextField}
							type="number"
							step={1}
							min={0}
						/>
					</Col>
					<Col className="mb-1">
						<Field
							label="Rejected Net Wt."
							name="rejected_net_weight"
							component={ReduxFormTextField}
							type="number"
							disabled
						/>
					</Col>
					<Col className="mb-1">
						<Field
							label="Missing Cheese"
							name="missing_cheese"
							component={ReduxFormTextField}
							type="number"
							step={1}
							min={0}
						/>
					</Col>
					<Col className="mb-1">
						<Field
							label="Missing Net Wt."
							name="missing_net_weight"
							component={ReduxFormTextField}
							type="number"
							disabled
						/>
					</Col>
					<Col className="mb-1">
						<Field
							label="Sample Cheese"
							name="sample_cheese"
							component={ReduxFormTextField}
							type="number"
							step={1}
							min={0}
						/>
					</Col>
					<Col className="mb-1">
						<Field
							label="Sample Net Wt."
							name="sample_net_weight"
							component={ReduxFormTextField}
							type="number"
							disabled
						/>
					</Col>
				</Row>
				<Row>
					<Col className="mb-1">
						<Field
							label="No of final Cheeses"
							name="no_of_final_cheeses"
							component={ReduxFormTextField}
							type="number"
							disabled
						/>
					</Col>
					<Col className="mb-1">
						<Field
							label="Net batch Wt."
							name="final_cheese_net_weight"
							component={ReduxFormTextField}
							type="number"
							disabled
						/>
					</Col>
					<Col className="mb-1">
						<Field
							label="Wt of conditioning."
							name="conditioning_weight"
							component={ReduxFormTextField}
							type="number"
							step={0.0001}
							min={0}
						/>
					</Col>
					<Col className="mb-1">
						<Field
							label="Difference Wt."
							name="difference_weight"
							component={ReduxFormTextField}
							type="number"
							disabled
						/>
					</Col>
					<Col className="mb-1">
						<Field
							label="Moisture Percentage"
							name="moisture_percent"
							component={ReduxFormTextField}
							type="number"
							disabled
						/>
					</Col>
				</Row>
				<Row className="mt-3">
					<Col>
						<Tooltip
							title={
								meta?.remarks?.touched && errors?.remarks
									? errors?.remarks
									: null
							}
						>
							<Field
								component="textarea"
								maxLength={200}
								className={`form-control border border-${
									meta?.remarks?.touched && errors?.remarks
										? "danger"
										: "secondary"
								}`}
								rows="4"
								name="remarks"
								placeholder="Enter remarks"
							/>
						</Tooltip>
					</Col>
				</Row>
			</Modal.Body>
			{renderModalButtons(onCancel, isUpdatingMaster)}
		</form>
	);
};

export default reduxForm({
	form: formName,
	destroyOnUnmount: false,
	enableReinitialize: true,
	keepDirtyOnReinitialize: true,
	initialValues: {
		missing_cheese : 0,
		rejected_cheese : 0,
		sample_cheese : 0,
	  },	
	validate: values => {
		const errors = {};

		isRequired(values?.issue_date) &&
			(errors.issue_date = "Required");
		isRequired(values?.issue_to_department) &&
			(errors.issue_to_department = "Required");
		isRequired(values?.rejected_cheese) &&
			(errors.rejected_cheese = "Required");
		isRequired(values?.missing_cheese) && (errors.missing_cheese = "Required");
		isRequired(values?.sample_cheese) && (errors.sample_cheese = "Required");
		// isRequired(values?.remarks) && (errors.remarks = "Required");
		values?.issue_to_department === "Winding Department" && (
			isRequired(values?.process_type) && (errors.process_type = "Required")
		)
		values?.issue_to_department === "Dispatch Department" && (
			isRequired(values?.total_cartons) && (errors.total_cartons = "Required")
		)

		+values?.no_of_final_cheeses <= 0 &&
			(errors.no_of_final_cheeses =
				"No of final cheese should be greater than 0");
		+values?.conditioning_weight < (+values?.final_cheese_net_weight * .95) &&
			(errors.conditioning_weight =
				"Conditioning weight went below 5% tolerance.");

		return errors;
	},
})(QcIssueToDepartmentForm);
