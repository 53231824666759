import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";

import { addMasterList } from "actions/master";
import { closeModal } from "actions/modal";
import { useState } from "react";
import { getActiveModal } from "reducers/modal";
import { destroy } from "redux-form";
import QcIssueToDepartmentConfirmation from "./QcIssueToDepartmentConfirmation";
import QcIssueToDepartmentForm, { formName } from "./QcIssueToDepartmentForm";

export const qcItdModalName = "qcItdModal";

const QcIssueToDepartmentModal = () => {
	const dispatch = useDispatch();
	const activeModal = useSelector(getActiveModal);
	const open = activeModal?.name === qcItdModalName;
	const data = activeModal?.data || {};

	const [page, setPage] = useState(1);


	const handleSubmit = formData => {
		delete formData?.masterName;
		delete formData?.currentStatus;
		if(formData.remarks === undefined) formData.remarks = null;
		if(formData.process_type === undefined) formData.process_type = null;
		if(formData.total_cartons === undefined) formData.total_cartons = 0;
		// formData.list_type="Others";
		if (data?.id) {
			dispatch(
				addMasterList(
					data?.masterName,
					formData,
					null,
					"application/json",
					() => data?.callback(),
					`?list_type="Others"`
				)
			);
		}
		setPage(1);
		dispatch(destroy(formName));
	};

	const handleClose = () => {
		dispatch(closeModal(qcItdModalName));
		dispatch(destroy(formName));
	};

	const handeNextPage = () => setPage(2);

	// const handePrevPage = () => setPage(1);
	const handePrevPage = () => {
		setPage(1);
		dispatch(destroy(formName));
		dispatch(closeModal(qcItdModalName));

	};

	return (
		<Modal
			show={open}
			onHide={handleClose}
			backdrop="static"
			keyboard={false}
			centered
			size="lg"
		>
			{page === 1 ? (
				<QcIssueToDepartmentForm
					initialValues={data}
					onSubmit={handeNextPage}
					onCancel={handleClose}
				/>
			) : (
				<QcIssueToDepartmentConfirmation
					onSubmit={handleSubmit}
					onCancel={handePrevPage}
				/>
			)}
		</Modal>
	);
};

export default QcIssueToDepartmentModal;
