import { Tabs } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import CreateItd from "./CreateItd";
import ListItd from "./ListItd";
import RejectedStock from "./RejectedStock";

const items = [
	{
		key: "create_itd",
		label: `Create Issue to Department`,
	},
	{
		key: "list_itd",
		label: `Issue to Department List`,
	},
	{
		key: "rejected_sample_stock",
		label: `Rejected/Sample Stock`,
	},
];

const DyeingItd = () => {
	const { type } = useParams();
	const navigate = useNavigate();

	const onChange = key => {
		let url = "";
		if (key === "create_itd") url = "create_itd/dyeing_production/6";
		else if (key === "list_itd") url = "list_itd/list/1";
		else if (key === "rejected_sample_stock") url = "rejected/sample_stock/3";

		navigate(`/dyeing-dyeing-itd/${url}`);
	};

	const renderModule = () => {
		if (type === "create_itd") return <CreateItd />;
		else if (type === "list_itd") return <ListItd />;
		else if (type === "rejected") return <RejectedStock />;
	};

	return (
		<>
			<Tabs
				type="card"
				defaultActiveKey={type}
				items={items}
				onChange={onChange}
			/>
			{renderModule()}
		</>
	);
};
export default DyeingItd;
