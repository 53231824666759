import { Segmented, Table } from "antd";
import { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addMasterList, getMasterList } from "actions/master";
import { openModal } from "actions/modal";
import CustomPagination from "components/CustomPagination";
import useMasterLogic from "customHooks/useMasterLogic";
import useSearchFilter from "customHooks/useSearchFilter";
import { useNavigate, useParams } from "react-router-dom";
import {
	getDyeingQcMasterList,
	getDyeingQcPagination,
	getIsFetchingMasterList,
} from "reducers/master";
import { confirmationModalName } from "utils/ConfirmationModal";
import { renderActions } from "utils/renderActions";
import QcIssueToDepartmentModal, {
	qcItdModalName,
} from "./QcIssueToDepartmentModal";
import QcNotOkModal, {qcNotOkModalName} from "../QcNotOkModal/QcNotOkModal";
import Filter from "utils/V2Comp/Filter";
import TotalCartonsModal, { totalCartonsModal } from "./TotalCartonsModal";

const tableName = "dyeing-qc";

const CreateItd = () => {
	const { list_type, status } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const dyeingItdList = useSelector(getDyeingQcMasterList);

	const { pageSize, totalElements, currentPage } = useSelector(
		getDyeingQcPagination
	);
	const isFetchingMasterList = useSelector(getIsFetchingMasterList);

	const { tableFilters } = useSearchFilter();
	const [queryData, setQueryData] = useState({})

	useEffect(()=>{
		setQueryData({})
	},[status])

	const getDyeingQcData = useCallback(
		(pageNum = 1, itemsPerPage = 10) => {
			if(status == 7) {
				//in case of downgrade table
				dispatch(
					getMasterList(tableName, {
						page: pageNum,
						page_size: 40,
						list_type,
						status,
						...tableFilters,
						...queryData,
					}, true)
				);
			}else{
				dispatch(
					getMasterList(tableName, {
						page: pageNum,
						page_size: 40,
						list_type,
						status,
						...tableFilters,
						...queryData,
					})
				);
			}
			
		},
		[dispatch, list_type, tableFilters, status, queryData]
	);

	const { handlePageChange } = useMasterLogic(getDyeingQcData);

	const issueToDepartment = (dyeing_production_id, issue_to_department, list_type="Others", total_cartons) =>
		dispatch(
			openModal(confirmationModalName, {
				onSubmit: () =>
					dispatch(
						addMasterList(
							"dyeing-itd",
							{
								dyeing_production_id,
								issue_to_department,
								total_cartons,
								// list_type,
							},
							status,
							"application/json",
							() => getDyeingQcData(),
							`?list_type=${issue_to_department === "Soft Department" ? "Soft" : list_type}`
						)
					),
			})
		);
	const issueToSoft = (dyeing_production_id, issue_to_department, total_cheese, total_net_weight, list_type="Others", total_cartons) =>
		dispatch(
			openModal(confirmationModalName, {
				onSubmit: () =>
					dispatch(
						addMasterList(
							"dyeing-itd",
							{
								dyeing_production_id,
								issue_to_department,
								total_cheese,
								total_net_weight,
								total_cartons,
							},
							status,
							"application/json",
							() => getDyeingQcData(),
							`?list_type=${issue_to_department === "Soft Department" ? "Soft" : list_type}`
						)
					),
			})
		);

		const [isDisable, setIsDisable] = useState(false);
		const onNotOkClick = useCallback(
			(id, total_cartons) =>
				dispatch(
					openModal(qcNotOkModalName, {
						id,
						status: 4,
						masterName: tableName,
						currentStatus: status,
						not_ok_observation: null,
						not_ok_description: null,
						not_ok_reason: null,
						not_ok_remarks: null,
						total_cartons: total_cartons
					}),
					setIsDisable(false)
				),
			[dispatch]
		);
		console.log('list type', list_type)
	const columns = [
		{
			title: "Job Card No",
			dataIndex: "job_card_no",
			key: "job_card_no",
			width: "auto",
		},
		{
			title: "Job Card Date",
			dataIndex: "job_card_date",
			key: "job_card_date",
			width: "auto",
		},
		{
			title: "Machine No",
			dataIndex: "machine_no",
			key: "machine_no",
			width: "auto",
		},
		{
			title: "Party Name",
			dataIndex: "party_name",
			key: "party_name",
			width: "auto",
		},
		{
			title: "Quality Name",
			dataIndex: ["yarn_quality", "name"],
			key: "yarn_quality_name",
			width: "auto",
		},
		{
			title: "Lot No",
			dataIndex: ["lot", "lot_no"],
			key: "lot_no",
			width: "auto",
		},
		{
			title: "Shade No",
			dataIndex: ["shade", "shade_no"],
			key: "shade_no",
			width: "auto",
		},
		{
			title: "Batch No",
			dataIndex: "batch_no",
			key: "batch_no",
			width: "auto",
		},
		{
			title: "Process",
			dataIndex: "process_name",
			key: "process_name",
			width: "auto",
		},
		{
			title: "No. Of Cheese",
			dataIndex: "total_cheese",
			key: "total_cheese",
			width: "auto",
		},
		{
			title: "Quantity (Nt Wt)",
			dataIndex: "total_net_weight",
			key: "total_net_weight",
			width: "auto",
		},
		{
			title: "Accepted (Nt Wt)",
			dataIndex: "accepted_net_weight",
			key: "accepted_net_weight",
			width: "auto",
		},
		{
			title: "Actions",
			key: "Actions",
			dataIndex: "action",
			width: "10%",
			render: (_, data) => {
				const items = [];

				if (+status === 7)
					items.push({
						label: "Issue to Dispatch",
						onClick: () =>
							// issueToDepartment(
							// 	list_type === "dyeing_production"
							// 		? data?.dyeing_production_id
							// 		: data?.id,
							// 	"Dispatch Department",
							// 	"Downgrade",
							// 	data?.total_cartons || 0,
							// ),
							dispatch(
								openModal(totalCartonsModal, {
									...data,
									masterName: "dyeing-itd",
									callback: () => getDyeingQcData(),
								})
							),
					});

				if ([7, 6, 4].includes(+status))
					items.push({
						label: "Issue to Soft",
						onClick: () => status === "6" ?
							issueToSoft( //issueToDepartment
								data?.id,
								"Soft Department",
								data?.pending_cheese,//added
								data?.pending_net_weight,//added
								"Others",
								data?.total_cartons || 0,
							) :
							issueToSoft(
								data?.dyeing_production_id,
								"Soft Department",
								data?.total_cheese,
								data?.total_net_weight,
								"Others",
								data?.total_cartons || 0,
							)
					});
				if (+status === 3)
					items.push({
						label: "Issue to Department",
						onClick: () =>
							dispatch(
								openModal(qcItdModalName, {
									...data,
									masterName: "dyeing-itd",
									callback: () => getDyeingQcData(),
								})
							),
					},{
						label: "Not OK",
						onClick: () => onNotOkClick(data?.id, data?.total_cartons || 0),

					}
					);

				return renderActions(items);
			},
		},
	];

	return (
		<>
			<div className="d-flex">
				<Segmented
					className="mt-1 mb-2 fs-6"
					options={[
						{ label: "Production Not Ok", value: "dyeing_production/6" },
						{ label: "Downgrade", value: "dyeing_production/7" },
						{ label: "QC Not Ok", value: "dyeing_qc/4" },
						// { label: "QC Downgrade", value: "dyeing_qc/7" },
						{ label: "QC Ok", value: "dyeing_qc/3" },
					]}
					value={`${list_type}/${status}`}
					onChange={val => navigate(`/dyeing-dyeing-itd/create_itd/${val}`)}
				/>
			</div>
			<Filter queryData={queryData} setQueryData={setQueryData} toShow={['yarn_quality_id', 'shade_id', 'lot_id', 'job_card_no']}/>
			<Table
				dataSource={dyeingItdList}
				columns={columns}
				rowKey={dyeingItdList => dyeingItdList?.id}
				pagination={false}
				loading={isFetchingMasterList}
				bordered
			/>
			{dyeingItdList?.length ? (
				<CustomPagination
					totalPages={Math.ceil(totalElements / pageSize)}
					itemsPerPage={pageSize}
					totalItems={totalElements}
					currentPage={currentPage}
					handlePageChange={handlePageChange}
				/>
			) : null}
			<QcIssueToDepartmentModal />
			<TotalCartonsModal/>
			<QcNotOkModal isDisable={isDisable} setIsDisable={setIsDisable} fromItd={true}/>
		</>
	);
};

export default CreateItd;
