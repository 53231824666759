export const observationOptions = [
	{ label: "LIGHT", value: "LIGHT" },
	{ label: "LIGHT & DARK", value: "LIGHT & DARK" },
	{ label: "UNEVEN", value: "UNEVEN" },
	{ label: "TONAL DIFFRENCE", value: "TONAL DIFFRENCE" },
	{ label: "SHADE OUT", value: "SHADE OUT" },
	{ label: "FASTNESS POOR", value: "FASTNESS POOR" }
];

export const descriptionOptions = [
	{ label: "YELLOWER", value: "YELLOWER" },
	{ label: "REDER", value: "REDER" },
	{ label: "GREENER", value: "GREENER" },
	{ label: "BLUER", value: "BLUER" },
	{ label: "LIGHTER", value: "LIGHTER" },
	{ label: "DARKER", value: "DARKER" },
];

export const reasonOptions = [
	{
		label: "BOILER TEMP. DOWN LONG TIME",
		value: "BOILER TEMP. DOWN LONG TIME",
		type: "LIGHT",
	},
	{ label: "M/C PROBLEM", value: "M/C PROBLEM", type: "LIGHT" },

	{
		label: "RM .TIGHT PACKAGE",
		value: "RM .TIGHT PACKAGE",
		type: "LIGHT & DARK",
	},
	{ label: "RM .BULGING", value: "RM .BULGING", type: "LIGHT & DARK" },
	{
		label: "RM .DENSITY VARIATION",
		value: "RM .DENSITY VARIATION",
		type: "LIGHT & DARK",
	},
	{
		label: "RM .HAMMERING UNPROPER",
		value: "RM .HAMMERING UNPROPER",
		type: "LIGHT & DARK",
	},
	{
		label: "LOOSE LOCK WHILE LOADING",
		value: "LOOSE LOCK WHILE LOADING",
		type: "LIGHT & DARK",
	},
	{ label: "RM LOT CHANGE", value: "RM LOT CHANGE", type: "LIGHT & DARK" },

	{
		label: "LOOSE LOCK WHILE LOADING",
		value: "LOOSE LOCK WHILE LOADING",
		type: "UNEVEN",
	},
	{
		label: "WRONG COMBINATION OF DYES",
		value: "WRONG COMBINATION OF DYES",
		type: "UNEVEN",
	},
	{
		label: "SUPPLIER DYES ISSUE",
		value: "SUPPLIER DYES ISSUE",
		type: "UNEVEN",
	},
	{
		label: "NOT PROPER COLOUR DOSING",
		value: "NOT PROPER COLOUR DOSING",
		type: "UNEVEN",
	},
	{
		label: "WRONG SELECTION OF PROGRAM",
		value: "WRONG SELECTION OF PROGRAM",
		type: "UNEVEN",
	},
	{
		label: "TIGHT PACKAGE RM",
		value: "TIGHT PACKAGE RM",
		type: "UNEVEN",
	},

	{ label: "RM LOT CHANGE", value: "RM LOT CHANGE", type: "TONAL DIFFRENCE" },
	{ label: "M/C PROBLEM", value: "M/C PROBLEM", type: "TONAL DIFFRENCE" },
	{
		label: "WRONG COMBINATION OF DYES",
		value: "WRONG COMBINATION OF DYES",
		type: "TONAL DIFFRENCE",
	},
	{
		label: "SUPPLIER DYES ISSUE",
		value: "SUPPLIER DYES ISSUE",
		type: "TONAL DIFFRENCE",
	},
	{
		label: "NOT PROPER COLOUR DOSING",
		value: "NOT PROPER COLOUR DOSING",
		type: "TONAL DIFFRENCE",
	},
	{
		label: "LCR",
		value: "LCR",
		type: "TONAL DIFFRENCE",
	},
	{
		label: "LFR",
		value: "LFR",
		type: "TONAL DIFFRENCE",
	},
	{
		label: "Production Receipe Update",
		value: "Production Receipe Update",
		type: "TONAL DIFFRENCE",
	},
	{
		label: "WRONG SELECTION OF PROGRAM",
		value: "WRONG SELECTION OF PROGRAM",
		type: "TONAL DIFFRENCE",
	},
	{ label: "PH ISSUE", value: "PH ISSUE", type: "TONAL DIFFRENCE" },
	{ label: "OLD RECIPE", value: "OLD RECIPE", type: "TONAL DIFFRENCE" },

	{ label: "LAB WRONG RECIPE", value: "LAB WRONG RECIPE", type: "SHADE OUT" },
	{
		label: "SELECTION OF WRONG RECIPE",
		value: "SELECTION OF WRONG RECIPE",
		type: "SHADE OUT",
	},
	{
		label: "COLOUR WEIGHING PROBLEMS",
		value: "COLOUR WEIGHING PROBLEMS",
		type: "SHADE OUT",
	},

	{
		label: "SUBLIMATION POOR",
		value: "SUBLIMATION POOR",
		type: "FASTNESS POOR",
	},
	{
		label: "RUBBING FASTNESS POOR",
		value: "RUBBING FASTNESS POOR",
		type: "FASTNESS POOR",
	},
	{
		label: "WASHING FASTNESS POOR",
		value: "WASHING FASTNESS POOR",
		type: "FASTNESS POOR",
	},
];
