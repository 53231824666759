
import { Col, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getIsUpdatingMasterList } from "reducers/master";
import {
	Field,
	getFormMeta,
	getFormSyncErrors,
	reduxForm,
} from "redux-form";

import { ReduxFormTextField } from "utils/ReduxFormTextField";
import { renderModalButtons } from "utils/renderModalButtons";
import { isRequired } from "utils/validations";

export const formName = "totalCartonForm";


const TotalCartonsForm = ({ onCancel, handleSubmit }) => {

	const isUpdatingMaster = useSelector(getIsUpdatingMasterList);
	const errors = useSelector(getFormSyncErrors(formName));
	const meta = useSelector(getFormMeta(formName));


	return (
		<form onSubmit={handleSubmit}>
			<Modal.Header closeButton>
				<Modal.Title>
					<span>Issue To Dispatch</span>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col className="mb-1">
						<Field
							label="Total Cartons"
							name="total_cartons"
							component={ReduxFormTextField}
							type="number"
							step={1}
							min={0}
                            touched={meta?.total_cartons?.touched}
							error={errors?.total_cartons}
						/>
					</Col>
				</Row>
			</Modal.Body>
			{renderModalButtons(onCancel, isUpdatingMaster)}
		</form>
	);
};

export default reduxForm({
	form: formName,
	destroyOnUnmount: false,
	enableReinitialize: true,
	keepDirtyOnReinitialize: true,	
    initialValues:{},
	validate: values => {
		const errors = {};

		isRequired(values?.total_cartons) &&
			(errors.total_cartons = "Required");

		return errors;
	},
})(TotalCartonsForm);
