import {
    GET_WINDING_STOCK_LIST,
    GET_WINDING_PROD_LIST,
    GET_WINDING_QC_LIST,
    UPDATE_WINDING_LIST,
    GET_WINDING_QC_DETAILS_LIST,
} from '../constants/winding'


const initialState = {
    isFetching:false,
    isUpdating:false,
    isAdding:false,
    windingStockData:[],
    windingStockPagination:{
        pageSize: 40,
		totalElements: 40,
		currentPage: 1,
    },
    windingProdData:[],
    windingProdPagination:{
        pageSize: 40,
		totalElements: 40,
		currentPage: 1,
    },
    windingQcData:[],
    windingQcPagination:{
        pageSize: 40,
		totalElements: 40,
		currentPage: 1,
    },
    windingQcDetailsData:[],
}

export const windingReducer = (state = initialState, { type, payload }) => {
    switch(type){
        case GET_WINDING_STOCK_LIST.REQUEST:
            return {...state, isFetching:true};
        case GET_WINDING_STOCK_LIST.SUCCESS:
            return {...state,isFetching:false, windingStockData:payload?.results, windingStockPagination: {
                pageSize: 40,
                currentPage:payload?.page || 1,
		        totalElements: payload?.count,
            }};
        case GET_WINDING_STOCK_LIST.FAILURE:
            return {...state, isFetching:false, error:payload};
        case GET_WINDING_PROD_LIST.REQUEST:
            return {...state, isFetching:true};
        case GET_WINDING_PROD_LIST.SUCCESS:
            return {...state,isFetching:false, windingProdData:payload?.results, windingProdPagination: {
                pageSize: 40,
                currentPage:payload?.page || 1,
		        totalElements: payload?.count,
            }};
        case GET_WINDING_PROD_LIST.FAILURE:
            return {...state, isFetching:false, error:payload};
        case UPDATE_WINDING_LIST.REQUEST:
            return {...state, isUpdating:true};
        case UPDATE_WINDING_LIST.SUCCESS:
            return {...state,isUpdating:false};
        case UPDATE_WINDING_LIST.FAILURE:
            return {...state, isUpdating:false};
        case GET_WINDING_QC_LIST.REQUEST:
            return {...state, isFetching:true};
        case GET_WINDING_QC_LIST.SUCCESS:
            return {...state,isFetching:false, windingQcData:payload?.results, windingQcPagination: {
                pageSize: 40,
                currentPage:payload?.page || 1,
                totalElements: payload?.count,
            }};
        case GET_WINDING_QC_LIST.FAILURE:
            return {...state, isFetching:false, error:payload};
        case GET_WINDING_QC_DETAILS_LIST.REQUEST:
            return {...state, isFetching:true};
        case GET_WINDING_QC_DETAILS_LIST.SUCCESS:
            return {...state,isFetching:false, windingQcDetailsData:payload?.results};
        case GET_WINDING_QC_DETAILS_LIST.FAILURE:
            return {...state, isFetching:false, error:payload};
        default:
            return state;
    }
}