import {
    GET_QUALITY_LIST,
    GET_SHADE_LIST,
    GET_LOT_LIST,
    GET_JOB_CARD_LIST,
    GET_PARTY_NAME_LIST,
    GET_SLIP_NO_LIST,
    GET_INVOICE_LIST
} from '../constants/filter'


const initialState = {
    isQualityFetching:false,
    isShadeFetching:false,
    isLotFetching:false,
    isJobCardFetching:false,
    isPartyNameFetching:false,
    isSlipFetching:false,
    isInvoiceFetching:false,
    qualityListData:[],
    lotListData:[],
    shadeListData:[],
    jobCardListData:[],
    partyNameListData:[],
    slipNoListData:[],
    invoiceListData:[],
    message:null,
    error:null,
}

export const filterReducer = (state = initialState, { type, payload }) => {
    switch(type){
        case GET_QUALITY_LIST.REQUEST:
            return {...state, isQualityFetching:true};
        case GET_QUALITY_LIST.SUCCESS:
            return {...state,isQualityFetching:false, qualityListData:payload?.results};
        case GET_QUALITY_LIST.FAILURE:
            return {...state, isQualityFetching:false, error:payload};
        case GET_LOT_LIST.REQUEST:
            return {...state, isLotFetching:true};
        case GET_LOT_LIST.SUCCESS:
            return {...state,isLotFetching:false, lotListData:payload?.results};
        case GET_LOT_LIST.FAILURE:
            return {...state, isLotFetching:false, error:payload};
        case GET_SHADE_LIST.REQUEST:
            return {...state, isShadeFetching:true};
        case GET_SHADE_LIST.SUCCESS:
            return {...state,isShadeFetching:false, shadeListData:payload?.results};
        case GET_SHADE_LIST.FAILURE:
            return {...state, isShadeFetching:false, error:payload};
        case GET_JOB_CARD_LIST.REQUEST:
            return {...state, isJobCardFetching:true};
        case GET_JOB_CARD_LIST.SUCCESS:
            return {...state,isJobCardFetching:false, jobCardListData:payload?.results};
        case GET_JOB_CARD_LIST.FAILURE:
            return {...state, isJobCardFetching:false, error:payload};
        case GET_PARTY_NAME_LIST.REQUEST:
            return {...state, isPartyNameFetching:true};
        case GET_PARTY_NAME_LIST.SUCCESS:
            return {...state,isPartyNameFetching:false, partyNameListData:payload?.results};
        case GET_PARTY_NAME_LIST.FAILURE:
            return {...state, isPartyNameFetching:false, error:payload};
        case GET_SLIP_NO_LIST.REQUEST:
            return {...state, isSlipFetching:true};
        case GET_SLIP_NO_LIST.SUCCESS:
            return {...state,isSlipFetching:false, slipNoListData:payload?.results};
        case GET_SLIP_NO_LIST.FAILURE:
            return {...state, isSlipFetching:false, error:payload};
        case GET_INVOICE_LIST.REQUEST:
            return {...state, isInvoiceFetching:true};
        case GET_INVOICE_LIST.SUCCESS:
            return {...state,isInvoiceFetching:false, invoiceListData:payload?.results};
        case GET_INVOICE_LIST.FAILURE:
            return {...state, isInvoiceFetching:false, error:payload};

            
        default:
            return state;
    }
}