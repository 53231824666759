import Card from "react-bootstrap/Card";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { userLogin } from "actions/user";
import { getUserDetails } from "reducers/user";

import LoginForm from "./LoginForm";
import './login.css'

const LoginPage = () => {
	const dispatch = useDispatch();

	const user = useSelector(getUserDetails);

	const handleSubmit = formData => {
		dispatch(userLogin(formData));
	};

	if (user?.isLoggedIn && user?.token) {
		return <Navigate to="/dashboard" />;
	}

	return (
		<div className="login-page-bg vh-100">
			<div
				className="d-flex justify-content-center align-items-center"
				// style={{
				// 	height: "60vh",
				// }}
			>
				<div className="login-bg shadow">
				<Card className="login-inner">
					<div className="p-3">
						<img
							src="/images/sanimo.PNG"
							alt="Sanimo"
							className="rounded shadow ms-3"
							style={{ height: 50 }}
						></img>
						<LoginForm
							onSubmit={handleSubmit}
							disabled={user?.isUserLoggingIn}
						/>
					</div>
				</Card>
				</div>
				
			</div>
		</div>
	);
};

export default LoginPage;
