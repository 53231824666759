import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";

import { addMasterList } from "actions/master";
import { closeModal } from "actions/modal";
import { useState } from "react";
import { getActiveModal } from "reducers/modal";
import { destroy } from "redux-form";
import TotalCartonsForm, {formName} from "./TotalCartonsForm";
import TotalCartonsConfirm from "./TotalCartonsConfirm";

export const totalCartonsModal = "totalCartonsModal";

const TotalCartonsModal = ({initialData}) => {
	const dispatch = useDispatch();
	const activeModal = useSelector(getActiveModal);
	const open = activeModal?.name === totalCartonsModal;
	const data = activeModal?.data || {};

	const [page, setPage] = useState(1);


	const handleSubmit = formData => {
        let postData = {
                dyeing_production_id: formData?.dyeing_production_id,
                issue_to_department:'Dispatch Department',
                total_cartons: Number(formData?.total_cartons)
        }
			dispatch(
                addMasterList(
                    "dyeing-itd",
                    postData,
                    7,
                    "application/json",
                    () => initialData?.getDyeingQcData(),
                    `?list_type=Downgrade`
			)
        )
		setPage(1);
		dispatch(destroy(formName));
	};

	const handleClose = () => {
		dispatch(closeModal(totalCartonsModal));
		dispatch(destroy(formName));
	};

	const handeNextPage = () => setPage(2);

	// const handePrevPage = () => setPage(1);
	const handePrevPage = () => {
		setPage(1);
		dispatch(destroy(formName));
		dispatch(closeModal(totalCartonsModal));

	};

	return (
		<Modal
			show={open}
			onHide={handleClose}
			backdrop="static"
			keyboard={false}
			centered
			size="lg"
		>
			{page === 1 ? (
				<TotalCartonsForm
					initialValues={data}
					onSubmit={handeNextPage}
					onCancel={handleClose}
				/>
			) : (
				<TotalCartonsConfirm
					onSubmit={handleSubmit}
					onCancel={handePrevPage}
				/>
			)}
		</Modal>
	);
};

export default TotalCartonsModal;
