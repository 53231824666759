import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";

import { editMasterList } from "actions/master";
import { closeModal } from "actions/modal";
import { useState } from "react";
import { getActiveModal } from "reducers/modal";
import { destroy } from "redux-form";
import QcNotOkConfirmationForm from "./QcNotOkConfirmationForm";
import QcNotOkForm, { formName } from "./QcNotOkForm";

export const qcNotOkModalName = "qcNotOkModal";

const QcNotOkModal = ({ isDisable, setIsDisable, fromItd=false }) => {
	const dispatch = useDispatch();
	const activeModal = useSelector(getActiveModal);
	const open = activeModal?.name === qcNotOkModalName;
	const data = activeModal?.data || {};

	const [page, setPage] = useState(1);

	const handleSubmit = formData => {
		delete formData?.masterName;
		delete formData?.currentStatus;
		if (data?.id) {
			dispatch(editMasterList(data?.masterName, formData, data?.currentStatus,"application/json", null, fromItd));
		}
		setPage(1);
		dispatch(destroy(formName));
	};

	const handleClose = () => {
		dispatch(closeModal(qcNotOkModalName));
		dispatch(destroy(formName));
		setIsDisable(false);
	};

	const handeNextPage = () => setPage(2);

	const handePrevPage = () => setPage(1);

	return (
		<Modal
			show={open}
			onHide={handleClose}
			backdrop="static"
			keyboard={false}
			centered
		>
			{page === 1 ? (
				<QcNotOkForm
					initialValues={data}
					onSubmit={handeNextPage}
					onCancel={handleClose}
					isDisable={isDisable}
				/>
			) : (
				<QcNotOkConfirmationForm
					onSubmit={handleSubmit}
					onCancel={handePrevPage}
				/>
			)}
		</Modal>
	);
};

export default QcNotOkModal;
