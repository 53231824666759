import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";

import { closeModal } from "actions/modal";
import { getActiveModal } from "reducers/modal";
import ConfirmationForm from "./ConfirmationForm";

export const confirmationModalName = "confirmationModal";

const ConfirmationModal = () => {
	const dispatch = useDispatch();
	const activeModal = useSelector(getActiveModal);
	const open = activeModal?.name === confirmationModalName;
	const data = activeModal?.data || {};

	const handleSubmit = () => data?.onSubmit();

	const handleClose = () => dispatch(closeModal(confirmationModalName));

	return (
		<Modal
			show={open}
			onHide={handleClose}
			backdrop="static"
			keyboard={false}
			centered
		>
			<ConfirmationForm
				onSubmit={handleSubmit}
				onCancel={handleClose}
				title={data?.title}
				bodyText={data?.bodyText}
			/>
		</Modal>
	);
};

export default ConfirmationModal;
