import {
    GET_DYEING_DOWNGRADE_LIST,
    ADD_DYEING_DOWNGRADE_JC,
    GET_DYEING_PLANNING_QUERY_DATA,
    CREATE_REDYEING_PLAN,
    CLEAR_ERROR
} from '../constants/dyeing'


const initialState = {
    isFetching:false,
    isAdding:false,
    isFetchingQueryData:false,
    downgradeData:[],
    queryData:[],
    downgradePagination:{
        pageSize: 40,
		totalElements: 40,
		currentPage: 1,
    },
    message:null,
    error:null,
}

export const dyeingDowngradeReducer = (state = initialState, { type, payload }) => {
    switch(type){
        case CLEAR_ERROR:
            return {...state, error:null, message:null, isFetchingQueryData:false};
        case GET_DYEING_DOWNGRADE_LIST.REQUEST:
            return {...state, isFetching:true};
        case GET_DYEING_DOWNGRADE_LIST.SUCCESS:
            return {...state,isFetching:false, downgradeData:payload?.results, downgradePagination: {
                pageSize: 40,
                currentPage:payload?.page || 1,
		        totalElements: payload?.count,
            }};
        case GET_DYEING_DOWNGRADE_LIST.FAILURE:
            return {...state, isFetching:false, error:payload};
        case GET_DYEING_PLANNING_QUERY_DATA.REQUEST:
            return {...state, isFetchingQueryData:true};
        case GET_DYEING_PLANNING_QUERY_DATA.SUCCESS:
            return {...state,isFetchingQueryData:false, queryData:payload?.results};
        case GET_DYEING_PLANNING_QUERY_DATA.FAILURE:
            return {...state, isFetchingQueryData:false, error:payload};
        case ADD_DYEING_DOWNGRADE_JC.REQUEST:
            return {...state, isAdding:true};
        case ADD_DYEING_DOWNGRADE_JC.SUCCESS:
            return {...state,isAdding:false, message:payload?.message};
        case ADD_DYEING_DOWNGRADE_JC.FAILURE:
            return {...state, isAdding:false, error:payload};
        case CREATE_REDYEING_PLAN.REQUEST:
            return {...state, isAdding:true};
        case CREATE_REDYEING_PLAN.SUCCESS:
            return {...state,isAdding:false, message:payload?.message};
        case CREATE_REDYEING_PLAN.FAILURE:
            return {...state, isAdding:false, error:payload};
        default:
            return state;
    }
}