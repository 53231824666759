export const masterName = "shade";
export const shadeModalName = "shadeModal";

export const shadeTypeOptions = [
	{ label: "NORMAL COLOUR", value: "NORMAL COLOUR" },
	{ label: "SENSATIVE COLOUR", value: "SENSATIVE COLOUR" },
	{ label: "PENDING", value: "PENDING" }
];

export const shadeNatureOptions = [
	{ label: "LIGHT SHADE", value: "LIGHT SHADE" },
	{ label: "MEDIUM SHADE", value: "MEDIUM SHADE" },
	{ label: "DARK SHADE", value: "DARK SHADE" },
	{ label: "HAVY DARK SHADE", value: "HAVY DARK SHADE" },
	{ label: "PENDING", value: "PENDING" }
];

export const shadeFamilyOptions = [
	{ label: "BLEACH WHITE", value: "BLEACH WHITE" },
	{ label: "OFF WHITE", value: "OFF WHITE" },
	{ label: "CREAM", value: "CREAM" },
	{ label: "BEIGE", value: "BEIGE" },
	{ label: "YELLOW", value: "YELLOW" },
	{ label: "RED", value: "RED" },
	{ label: "MAROON", value: "MAROON" },
	{ label: "BLACK", value: "BLACK" },
	{ label: "SKY BLUE", value: "SKY BLUE" },
	{ label: "ROYAL BLUE", value: "ROYAL BLUE" },
	{ label: "NAVY BLUE", value: "NAVY BLUE" },
	{ label: "BURGANDY", value: "BURGANDY" },
	{ label: "VIOLET", value: "VIOLET" },
	{ label: "PURPLE", value: "PURPLE" },
	{ label: "PINK", value: "PINK" },
	{ label: "NEON FLUORESCENT", value: "NEON FLUORESCENT" },
	{ label: "ORANGE", value: "ORANGE" },
	{ label: "FLUORESCENT ORANGE", value: "FLUORESCENT ORANGE" },
	{ label: "BROWN", value: "BROWN" },
	{ label: "MUSTARD", value: "MUSTARD" },
	{ label: "GAJARI", value: "GAJARI" },
	{ label: "MAHENDI", value: "MAHENDI" },
	{ label: "MORPICH", value: "MORPICH" },
	{ label: "GREEN", value: "GREEN" },
	{ label: "LAVENDER", value: "LAVENDER" },
	{ label: "SEA GREEN", value: "SEA GREEN" },
	{ label: "P. GREEN", value: "P. GREEN" },
	{ label: "PHIROZI", value: "PHIROZI" },
	{ label: "RANI", value: "RANI" },
	{ label: "ONION", value: "ONION" },
	{ label: "MAGENTA", value: "MAGENTA" },
	{ label: "OLIVE", value: "OLIVE" },
	{ label: "COFFEE", value: "COFFEE" },
	{ label: "RUST", value: "RUST" },
	{ label: "GREY", value: "GREY" },
];
