import {
    GET_STOCK_LIST,
    UPDATE_STOCK_LIST,
    ADD_STOCK_LIST,
    GET_ITD_LIST,
    UPDATE_ITD_LIST,
    ADD_ITD_LIST,
    CLEAR_ERROR
} from '../constants/heatSetting'


const initialState = {
    isFetching:false,
    isUpdating:false,
    isAdding:false,
    stockData:[],
    stockPagination:{
        pageSize: 40,
		totalElements: 40,
		currentPage: 1,
    },
    itdData:[],
    itdPagination:{
        pageSize: 40,
		totalElements: 40,
		currentPage: 1,
    },
    message:null,
    error:null,
}

export const heatSettingReducer = (state = initialState, { type, payload }) => {
    switch(type){
        case CLEAR_ERROR:
            return {...state, error:null, message:null};
        case GET_STOCK_LIST.REQUEST:
            return {...state, isFetching:true};
        case GET_STOCK_LIST.SUCCESS:
            return {...state,isFetching:false, stockData:payload?.results, stockPagination: {
                pageSize: 40,
                currentPage:payload?.page || 1,
		        totalElements: payload?.count,
            }};
        case GET_STOCK_LIST.FAILURE:
            return {...state, isFetching:false, error:payload};
        case GET_ITD_LIST.REQUEST:
            return {...state, isFetching:true};
        case GET_ITD_LIST.SUCCESS:
            return {...state,isFetching:false, itdData:payload?.results, itdPagination: {
                pageSize: 40,
                currentPage:payload?.page || 1,
		        totalElements: payload?.count,
            }};
        case GET_ITD_LIST.FAILURE:
            return {...state, isFetching:false, error:payload};
        default:
            return state;
    }
}