import React from 'react'
import Modal from "react-bootstrap/Modal";
import ConfirmationForm from './ConfirmForm';



const ActionModal = ({open, setOpen, handleAction, title, initialValues}) => {
 
 const handleClose = () =>{
    setOpen(false)
 }

 const renderItem = () =>{
    if(title === "Issue Sample to Downgrade" || title === "Issue to Redyeing" || title === "Issue to Downgrade"){
        return <ConfirmationForm
                handleAction={handleAction}
                handleClose={handleClose}
                title={title}
                initialValues={initialValues}
                />
    }else {
        return null;
    }
 }

    return (
    <Modal
			show={open}
			onHide={handleClose}
			backdrop="static"
			keyboard={false}
			centered
         size='lg'
         style={{
            zIndex:1050
         }}
		>
            {renderItem()}

		</Modal>
  )
}

export default ActionModal