import {
	shadeFamilyOptions,
	shadeNatureOptions,
} from "components/masters/shade/shadeConstants";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import { Field, getFormMeta, getFormSyncErrors, reduxForm } from "redux-form";
import { ReduxFormAsyncSelect } from "utils/ReduxFormAsyncSelect";
import { ReduxFormSelectField } from "utils/ReduxFormSelectField";

export const filtersFormName = "dyeingStockFilters";

const DyeingPlanningFilters = ({
	handleFilterSubmit,

	handleReset,
	status,
}) => {
	const meta = useSelector(getFormMeta(filtersFormName));
	const errors = useSelector(getFormSyncErrors(filtersFormName));
	//console.log(handleFilterSubmit);
	return (
		// <form onSubmit={handleSubmit}>
		<Row>
			{+status === 1 ? (
				<>
					<Col className="mb-1">
						<Field
							component={ReduxFormSelectField}
							name="shade_nature"
							label="Shade Nature"
							options={shadeNatureOptions}
							touched={meta?.shade_nature?.touched}
							error={errors?.shade_nature}
							onChange={handleFilterSubmit}
						/>
					</Col>
					<Col className="mb-1">
						<Field
							component={ReduxFormSelectField}
							name="shade_family"
							label="Shade Family"
							onChange={handleFilterSubmit}
							options={shadeFamilyOptions}
							touched={meta?.shade_family?.touched}
							error={errors?.shade_family}
						/>
					</Col>{" "}
				</>
			) : (
				<>
					<Col className="mb-1">
						<Field
							component={ReduxFormAsyncSelect}
							name="shade_id"
							label="Shade Number"
							placeholder="Enter Shade Number"
							masterDropdownName="shade"
							onChange={handleFilterSubmit}
						/>
					</Col>

					<Col className="mb-2">
						<Field
							component={ReduxFormAsyncSelect}
							name="lot_id"
							label="Lot Number"
							masterDropdownName="lot"
							placeholder="Lot Number"
							onChange={handleFilterSubmit}
						/>
					</Col>
				</>
			)}
			{/* <Col className="d-flex mt-2" xs={2}>
				<button
					className="btn btn-secondary align-self-center fs-7 fw-500"
					type="button"
					onClick={handleReset}
				>
					Reset
				</button>
				<button
					className="btn ms-2 me-2 align-self-center fs-7 fw-500 bg-color-orange color-white table-btn"
					type="submit"
				>
					Filter
				</button>
			</Col> */}
			<Col />
			<Col />
		</Row>
		// </form>
	);
};

export default reduxForm({ form: filtersFormName })(DyeingPlanningFilters);
