import { Tooltip } from "antd";

export const ReduxFormTextField = ({
	input,
	label,
	type = "text",
	maxLength = 200,
	placeholder,
	meta: { touched, error, warning },
	className = "mt-3",
	content,
	...inputProps
}) => {
	return (
		<>
			{label && (
				content ?
				<>
				<label htmlFor="textInput" className="ms-1 position-absolute">
					<span className="h6 text-muted px-1 rounded">
						{label}
					</span>
				</label>
				</>
				:
				<label htmlFor="textInput" className="ms-2 position-absolute">
					<span className="h6 small bg-white text-muted px-1 rounded">
						{label}
					</span>
				</label>
			)}
			<Tooltip title={touched && error ? error : null}>
				{type === 'textarea' ?
				<textarea
				{...input}
				id="textInput"
				className={`form-control ${className} border border-${
					touched && error ? "danger" : "secondary"
				}`}
				maxLength={maxLength}
				placeholder={placeholder}
				{...inputProps}
			/>
					:
					<input
					{...input}
					id="textInput"
					className={`form-control ${content && 'mt-4'} ${className} border border-${
						touched && error ? "danger" : "secondary"
					}`}
					type={type}
					maxLength={maxLength}
					placeholder={placeholder}
					{...inputProps}
				/>	
			}
				
			</Tooltip>
		</>
	);
};
