import { Modal } from "react-bootstrap";
import { reduxForm } from "redux-form";

const ConfirmationForm = ({
	handleSubmit,
	onCancel,
	title = "Confirmation",
	bodyText = "Are you sure you want to continue? This action is irreversible.",
}) => {
	return (
		<form onSubmit={handleSubmit}>
			<Modal.Header closeButton>
				<Modal.Title>
					<span style={{ textTransform: "capitalize" }}>{title}</span>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h6>{bodyText}</h6>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-secondary" type="button" onClick={onCancel}>
					Cancel
				</button>
				<button
					className="btn ms-3 align-self-center fs-7 fw-500 bg-color-orange color-white table-btn"
					type="submit"
				>
					Confirm
				</button>
			</Modal.Footer>
		</form>
	);
};

export default reduxForm({
	form: "confirmationForm",
})(ConfirmationForm);
